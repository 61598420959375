import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Avatar, CircularProgress, Divider } from '@mui/material';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import InputBase from '@mui/material/InputBase';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { VariableSizeList } from 'react-window';
import FormatarTelefone from '../../helpers/FormatarTelefone';
import debounce from 'lodash/debounce';

const LISTBOX_PADDING = 8;
const ROW_HEIGHT = 50;
const ITEMS_PER_PAGE = 20;

function renderRow(props) {
  const { data, index, style, handleSelectionChange, handleRemoveSelect } = props;
  const option = data[index];
  const selected = data.value?.some(v => v.id === option.id);

  return (
    <Box
      component="li"
      sx={{
        ...style,
        margin: '5px 5px !important',
        display: 'flex !important',
        alignItems: 'center !important',
        padding: '3px !important',
        borderRadius: '3px !important',
        ...(selected && {
          backgroundColor: 'rgb(0 0 0 / 10%) !important',
        }),
      }}
      onClick={(e) => {
        if (selected) {
          handleRemoveSelect(option.id);
          e.stopPropagation();
        }
        if (!selected) {
          handleSelectionChange(option.id);
          e.stopPropagation();
        }
      }}
    >
      <Avatar
        sx={{
          width: 30,
          height: 30,
          flexShrink: 0,
          mr: 1,
          mt: '2px',
        }}
        src={option.profilePicUrl}
        alt={option.name}
      />
      <Box sx={{ flexGrow: 1 }}>
        {option.name}
        <br />
        <span>{option?.number?.length > 13 ? 'Grupo' : FormatarTelefone(option?.number)}</span>
      </Box>
      {selected ? <CloseIcon /> : null}
    </Box>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, onScroll, hasMore, loadMore, loading, ...other } = props;
  const itemData = children;
  const itemCount = itemData.length;

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * ROW_HEIGHT;
    }
    return itemCount * ROW_HEIGHT;
  };

  const gridRef = useRef();

  const handleScroll = useCallback((e) => {
    if (!hasMore || loading) return;

    const list = e.target;
    if (list.scrollTop + list.clientHeight >= list.scrollHeight - 50) {
      loadMore();
    }
  }, [hasMore, loading, loadMore]);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          style={{ overflowX: 'hidden' }}
          ref={gridRef}
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={() => ROW_HEIGHT}
          overscanCount={5}
          itemCount={itemCount}
          onScroll={handleScroll}
        >
          {renderRow}
        </VariableSizeList>
        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
            <CircularProgress size={20} />
          </Box>
        )}
      </OuterElementContext.Provider>
    </div>
  );
});

const KanbanContactsAutoComplete = ({
  placeholder,
  search,
  value,
  setValue,
  loading: initialLoading,
  onSearch // Nova prop para função de busca
}) => {
  const [inputValue, setInputValue] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  // Função para carregar dados com debounce
  const debouncedSearch = useCallback(
    debounce(async (searchTerm, pageNum) => {
      setLoading(true);
      try {
        const result = await onSearch({
          search: searchTerm,
          page: pageNum,
          limit: ITEMS_PER_PAGE
        });

        if (pageNum === 1) {
          setOptions(result.data);
        } else {
          setOptions(prev => [...prev, ...result.data]);
        }

        setHasMore(result.hasMore);
      } catch (error) {
        console.error('Error fetching contacts:', error);
      } finally {
        setLoading(false);
      }
    }, 300),
    [onSearch]
  );

  // Efeito para carregar dados iniciais
  useEffect(() => {
    debouncedSearch(inputValue, 1);
  }, []);

  // Handler para mudança no input
  const handleInputChange = useCallback((e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    setPage(1);
    debouncedSearch(newValue, 1);
  }, [debouncedSearch]);

  // Handler para carregar mais dados
  const handleLoadMore = useCallback(() => {
    const nextPage = page + 1;
    setPage(nextPage);
    debouncedSearch(inputValue, nextPage);
  }, [page, inputValue, debouncedSearch]);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  }, [anchorEl]);

  const handleSelectionChange = useCallback((newId) => {
    const selectedLabel = options.find(label => label.id === newId);
    if (selectedLabel) {
      setValue(prev => [...prev, selectedLabel]);
    }
  }, [options, setValue]);

  const handleRemoveSelect = useCallback((id) => {
    setValue(prev => prev.filter(label => label.id !== id));
  }, [setValue]);

  const open = Boolean(anchorEl);
  const id = open ? 'github-label' : undefined;

  return (
    <React.Fragment>
      <Box sx={{ width: '100%', fontSize: 13 }}>
        <Box
          sx={{
            padding: '5px',
            borderRadius: '10px',
            ...(initialLoading
              ? {
                backgroundColor: theme => theme.palette.mode === 'dark' ? '#2a2f3a' : '#f1f3f5',
                borderColor: theme => theme.palette.mode === 'dark' ? '#5c6370' : '#c2c7cc',
              }
              : {
                cursor: 'pointer',
                transition: 'background-color 0.3s, border-color 0.3s',
                '&:hover': {
                  backgroundColor: theme => theme.palette.mode === 'dark' ? '#2a2f3a' : '#f1f3f5',
                  borderColor: theme => theme.palette.mode === 'dark' ? '#5c6370' : '#c2c7cc',
                },
              }),
            border: theme => `1px solid ${theme.palette.mode === 'dark' ? '#414955' : '#d8dce0'}`,
          }}
        >
          <Box
            display="flex"
            disabled={initialLoading}
            onClick={e => !initialLoading && handleClick(e)}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box sx={{ alignItems: 'center', display: 'flex' }}>
              <Label>{placeholder}</Label>
              <Divider sx={{ margin: '0px 10px' }} orientation="vertical" flexItem />
              {value?.length > 0 &&
                value?.map((label, index) => (
                  <StyledChip
                    sx={{ marginRight: '5px', borderRadius: '5px' }}
                    size="small"
                    key={index}
                    avatar={<Avatar alt={label.name} src={label.profilePicUrl} />}
                    label={label.name}
                  />
                ))}
            </Box>
            {initialLoading ? <CircularProgress size={20} /> : <AddIcon />}
          </Box>
        </Box>
      </Box>
      <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Box
              sx={theme => ({
                borderBottom: `1px solid ${'#30363d'}`,
                padding: '8px 10px',
                fontWeight: 600,
                ...theme.applyStyles('light', {
                  borderBottom: `1px solid ${'#eaecef'}`,
                }),
              })}
            >
              <Typography variant="body2" gutterBottom>
                {search}
              </Typography>
            </Box>
            <StyledAutocompletePopper>
              <Box sx={{ pb: 2 }}>
                <StyledInput
                  autoFocus
                  fullWidth
                  placeholder="Buscar..."
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </Box>
              <ListboxComponent
                value={value}
                handleSelectionChange={handleSelectionChange}
                handleRemoveSelect={handleRemoveSelect}
                hasMore={hasMore}
                loadMore={handleLoadMore}
                loading={loading}
              >
                {options}
              </ListboxComponent>
            </StyledAutocompletePopper>
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </React.Fragment>
  );
};

const Label = styled(Typography)(({ theme }) => ({
  marginLeft: '10px',
  fontSize: '0.75rem',
  fontWeight: 600,
  color: theme.palette.text.secondary,
}));

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: '#fff',
    padding: 0,
    maxHeight: '40vh',
    overflow: 'auto',
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      borderBottom: `1px solid  ${' #eaecef'}`,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]: {
        backgroundColor: theme.palette.action.hover,
      },
      ...theme.applyStyles('dark', {
        borderBottom: `1px solid  ${'#30363d'}`,
      }),
    },
    ...theme.applyStyles('dark', {
      backgroundColor: '#1c2128',
    }),
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${'#e1e4e8'}`,
  boxShadow: `0 8px 24px ${'rgba(149, 157, 165, 0.2)'}`,
  color: '#24292e',
  backgroundColor: '#fff',
  borderRadius: 6,
  width: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  ...theme.applyStyles('dark', {
    border: `1px solid ${'#30363d'}`,
    boxShadow: `0 8px 24px ${'rgb(1, 4, 9)'}`,
    color: '#c9d1d9',
    backgroundColor: '#1c2128',
  }),
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: '10px',
  width: '100%',
  borderBottom: `1px solid ${'#30363d'}`,
  '& input': {
    borderRadius: 4,
    backgroundColor: '#fff',
    border: `1px solid ${'#30363d'}`,
    padding: '5px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontSize: 14,
    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${'rgba(3, 102, 214, 0.3)'}`,
      borderColor: '#0366d6',
      ...theme.applyStyles('dark', {
        boxShadow: `0px 0px 0px 3px ${'rgb(12, 45, 107)'}`,
        borderColor: '#388bfd',
      }),
    },
    ...theme.applyStyles('dark', {
      backgroundColor: '#0d1117',
      border: `1px solid ${'#eaecef'}`,
    }),
  },
  ...theme.applyStyles('dark', {
    borderBottom: `1px solid ${'#eaecef'}`,
  }),
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  '& .MuiChip-deleteIcon': {
    color: theme.palette.grey[500],
  },
}));

export default React.memo(KanbanContactsAutoComplete);