import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import SettingsIcon from '@mui/icons-material/Settings';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import InputBase from '@mui/material/InputBase';
import Popper from '@mui/material/Popper';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import * as React from 'react';
import { CircularProgress, Divider, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const Label = styled(Typography)(({ theme }) => ({
  marginLeft: '10px',
  fontSize: '0.75rem',
  fontWeight: 600,
  color: theme.palette.text.secondary,
}));

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: '#fff',
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      borderBottom: `1px solid  ${' #eaecef'}`,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]: {
        backgroundColor: theme.palette.action.hover,
      },
      ...theme.applyStyles('dark', {
        borderBottom: `1px solid  ${'#30363d'}`,
      }),
    },
    ...theme.applyStyles('dark', {
      backgroundColor: '#1c2128',
    }),
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

function PopperComponent(props) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${'#e1e4e8'}`,
  boxShadow: `0 8px 24px ${'rgba(149, 157, 165, 0.2)'}`,
  color: '#24292e',
  backgroundColor: '#fff',
  borderRadius: 6,
  width: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  ...theme.applyStyles('dark', {
    border: `1px solid ${'#30363d'}`,
    boxShadow: `0 8px 24px ${'rgb(1, 4, 9)'}`,
    color: '#c9d1d9',
    backgroundColor: '#1c2128',
  }),
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: '10px',
  width: '100%',
  borderBottom: `1px solid ${'#30363d'}`,
  '& input': {
    borderRadius: 4,
    backgroundColor: '#fff',
    border: `1px solid ${'#30363d'}`,
    padding: '5px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontSize: 14,
    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${'rgba(3, 102, 214, 0.3)'}`,
      borderColor: '#0366d6',
      ...theme.applyStyles('dark', {
        boxShadow: `0px 0px 0px 3px ${'rgb(12, 45, 107)'}`,
        borderColor: '#388bfd',
      }),
    },
    ...theme.applyStyles('dark', {
      backgroundColor: '#0d1117',
      border: `1px solid ${'#eaecef'}`,
    }),
  },
  ...theme.applyStyles('dark', {
    borderBottom: `1px solid ${'#eaecef'}`,
  }),
}));

export default function KanbanPriorityAutoComplete({
  labels,
  placeholder,
  search,
  value,
  setValue,
  loading,
  handleCreate,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();

  // Verificar se labels não está vazio antes de remover duplicados
  const arrayData =
    labels.length > 0
      ? labels.reduce((acc, label) => {
          // Verifica se o id já existe no acumulador
          if (!acc.some((item) => item.id === label.id)) {
            acc.push(label);
          }
          return acc;
        }, [])
      : [];

  const handleClick = (event) => {
    setValue(value);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'github-label' : undefined;

  return (
    <React.Fragment>
      <Box sx={{ width: '100%', fontSize: 13 }}>
        <Box>
          <Box
            display="flex"
            disabled={loading}
            onClick={(e) => {
              !loading && handleClick(e);
            }}
            alignItems="center"
            justifyContent="space-between"
            sx={{
              padding: '5px',
              borderRadius: '10px',
              ...(loading
                ? {
                    backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#2a2f3a' : '#f1f3f5'),
                    borderColor: (theme) => (theme.palette.mode === 'dark' ? '#5c6370' : '#c2c7cc'),
                  }
                : {
                    cursor: 'pointer',
                    transition: 'background-color 0.3s, border-color 0.3s',
                    '&:hover': {
                      backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#2a2f3a' : '#f1f3f5'),
                      borderColor: (theme) => (theme.palette.mode === 'dark' ? '#5c6370' : '#c2c7cc'),
                    },
                  }),
              border: (theme) => `1px solid ${theme.palette.mode === 'dark' ? '#414955' : '#d8dce0'}`,
            }}
          >
            <Box sx={{ alignItems: 'center', display: 'flex' }}>
              <Label>{placeholder}</Label>
              <Divider sx={{ margin: '0px 10px 0px 10px' }} orientation="vertical" flexItem />
              {value?.length > 0 &&
                value?.map((label, index) => (
                  <Chip
                    key={index}
                    label={label.title}
                    size="small"
                    sx={{
                      borderRadius: '5px',
                      height: '22px',
                      backgroundColor: label.color,
                      color: theme.palette.getContrastText(label.color),
                    }}
                  />
                ))}
            </Box>
            {loading ? <CircularProgress size={20} /> : <AddIcon />}
          </Box>
        </Box>
      </Box>
      <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Box
              sx={(t) => ({
                borderBottom: `1px solid ${'#30363d'}`,
                padding: '8px 10px',
                fontWeight: 600,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                ...t.applyStyles('light', {
                  borderBottom: `1px solid ${'#eaecef'}`,
                }),
              })}
            >
              <Typography variant="body2" gutterBottom>
                {search}
              </Typography>
              <Tooltip title="Adicionar nova prioridade">
                <IconButton
                  onClick={() => {
                    handleCreate();
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Autocomplete
              open
              multiple
              onClose={(event, reason) => {
                if (reason === 'escape') {
                  handleClose();
                }
              }}
              value={value}
              onChange={(event, newValue, reason) => {
                if (
                  event.type === 'keydown' &&
                  (event.key === 'Backspace' || event.key === 'Delete') &&
                  reason === 'removeOption'
                ) {
                  return;
                }
                setValue(newValue);
              }}
              disableCloseOnSelect
              renderTags={() => null}
              noOptionsText={
                <Typography>
                  Nenhum item encontrado. Clique em
                  <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', mx: 0.5 }}>
                    <AddIcon color="primary" sx={{ fontSize: 'inherit', verticalAlign: 'middle' }} />
                  </Box>
                  para adicionar um novo.
                </Typography>
              }
              renderOption={(props, option, { selected }) => {
                const { key, ...optionProps } = props;
                return (
                  <li key={key} {...optionProps}>
                    <Box
                      component={DoneIcon}
                      sx={{ width: 17, height: 17, mr: '5px', ml: '-2px' }}
                      style={{
                        visibility: selected ? 'visible' : 'hidden',
                      }}
                    />
                    <Box
                      component="span"
                      sx={{
                        width: 14,
                        height: 14,
                        flexShrink: 0,
                        borderRadius: '3px',
                        mr: 1,
                        mt: '2px',
                      }}
                      style={{ backgroundColor: option.color }}
                    />
                    <Box
                      sx={(t) => ({
                        flexGrow: 1,
                        '& span': {
                          color: '#8b949e',
                          ...t.applyStyles('light', {
                            color: '#586069',
                          }),
                        },
                      })}
                    >
                      {option.title}
                      <br />
                      <span>{option.description}</span>
                    </Box>
                    <Box
                      component={CloseIcon}
                      sx={{ opacity: 0.6, width: 18, height: 18 }}
                      style={{
                        visibility: selected ? 'visible' : 'hidden',
                      }}
                    />
                  </li>
                );
              }}
              options={arrayData.sort((a, b) => a.title.localeCompare(b.title))}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <StyledInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder="Buscar..."
                />
              )}
              slots={{
                popper: PopperComponent,
              }}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </React.Fragment>
  );
}
