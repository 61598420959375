import { faker } from '@faker-js/faker';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import { Box, Chip, IconButton, Menu, MenuItem, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useRef, useState, useEffect } from 'react';
import { useEvent } from 'react-use';
import Iconify from '../Iconify';
import KanbanInput from '../KanbanInput';
import KanbanCard from '../KanbanCard';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KanbanColumnSettingsModal from '../KanbanColumnSettingsModal';

export default function KanbanColumn({
  kanban,
  index,
  column,
  cards,
  filters,
  assigneds,
  priorities,
  taskTitle,
  createCard,
  clearColumn,
  deleteColumn,
  renameColumn,
}) {
  const theme = useTheme();
  const [addingCard, setAddingCard] = useState(false);
  const [renamingCard, setRenamingCard] = useState(false);
  const [renameInputValue, setRenameInputValue] = useState(column?.title || '');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [filteredCards, setFilteredCards] = useState([]);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);

  const addCardInputRef = useRef(null);
  const renameCardInputRef = useRef(null);

  const handleClickOutside = (event) => {
    if (addCardInputRef.current && !addCardInputRef.current.contains(event.target)) {
      setAddingCard(false);
    }
    if (renameCardInputRef.current && !renameCardInputRef.current.contains(event.target)) {
      setRenamingCard(false);
    }
  };

  useEvent('click', handleClickOutside);

  const handleMenuOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setDropdownOpen(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setDropdownOpen(false);
  };

  const handleSaveRename = (value) => {
    setRenameInputValue(value);
    renameColumn({ ...column, title: value });
    setRenamingCard(false);
  };

  const handleCreateCard = (value) => {
    createCard(column.id, {
      id: faker.string.uuid(),
      title: value,
      reporter: faker.image.avatarGitHub(),
      priority: 'high',
    });
    setAddingCard(false);
  };

  const handleOpenSettingsModal = (columnId) => {
    setOpenSettingsModal(true);
  };

  useEffect(() => {
    const uniqueCards = () => {
      const unique = [];
      cards.forEach((card) => {
        const existingIndex = unique.findIndex((existingCard) => existingCard.id === card.id);
        if (existingIndex === -1) {
          unique.push(card);
        } else {
          unique[existingIndex] = card;
        }
      });
      return unique;
    };

    let filtered = uniqueCards();

    const filtros = filtered.filter((card) => {
      if (!filters) return true;

      const hasPriority =
        filters?.priorityFilter?.length > 0 &&
        filters.priorityFilter.some((priority) => card.kanbanPriorityId === priority.id);
      if (filters?.priorityFilter?.length > 0 && !hasPriority) return false;

      const hasAssignedUser =
        filters?.assignedFilter?.length > 0 &&
        card.assigned.some((assignedUser) =>
          filters.assignedFilter.some((filterUser) => filterUser.id === assignedUser.id)
        );
      if (filters?.assignedFilter?.length > 0 && !hasAssignedUser) return false;

      const hasCreator =
        filters?.createdByFilter?.length > 0 && filters.createdByFilter.some((user) => user.id === card.creator.id);
      if (filters?.createdByFilter?.length > 0 && !hasCreator) return false;

      return true;
    });

    setFilteredCards(filtros);
  }, [cards, filters]);

  return (
    <>
      <Draggable draggableId={column.id} index={index}>
        {(provided) => (
          <Paper
            {...provided.draggableProps}
            ref={provided.innerRef}
            variant="outlined"
            sx={{
              padding: '0px',
              borderRadius: 2,
              borderStyle: 'dashed',
              bgcolor: (theme) => (theme.palette.mode === 'light' ? 'rgb(244 246 248)' : 'rgb(37 44 53)'),
            }}
          >
            <Stack spacing={1}>
              <Box
                sx={{
                  width: '345px',
                  maxWidth: '345px',
                  mb: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  userSelect: 'none',
                  padding: '10px 5px 10px 5px',
                  borderRadius: '4px',
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <Box
                  sx={{
                    maxWidth: renamingCard ? '100%' : '80%',
                    ...(renamingCard
                      ? {
                          width: '100%',
                        }
                      : {
                          cursor: 'pointer',
                          transition: 'border 0.3s ease',
                          padding: '6px 6px 6px 6px',
                          '&:hover': {
                            border: (theme) => `1px solid ${theme.palette.primary.main}`,
                            borderRadius: '4px',
                          },
                        }),
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (renamingCard) return;
                    setRenamingCard(true);
                  }}
                >
                  {renamingCard ? (
                    <KanbanInput
                      ref={renameCardInputRef}
                      label="Titulo"
                      limit={25}
                      value={renameInputValue}
                      onSave={handleSaveRename}
                      onCancel={() => setRenamingCard(false)}
                    />
                  ) : (
                    <Box sx={{ display: 'flex' }}>
                      {filters?.showTaskCount && (
                        <Chip
                          variant="outlined"
                          color="primary"
                          label={column?.cards?.length || 0}
                          size="small"
                          sx={{ marginRight: '10px' }}
                        />
                      )}
                      <Typography variant="body1" fontWeight="bold" whiteSpace="pre-wrap">
                        {column.title}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box>
                  {!renamingCard && (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {isHovered && ( // Exibe o Tooltip apenas se isHovered for true
                        <Tooltip placement="top" arrow title="Mover coluna">
                          <Box {...provided.dragHandleProps}>
                            <IconButton sx={{ padding: '3px', marginRight: '10px', cursor: 'grab' }}>
                              <Iconify icon="material-symbols:ad-group-outline" />
                            </IconButton>
                          </Box>
                        </Tooltip>
                      )}
                      <Tooltip placement="top" arrow title="Opções da coluna">
                        <IconButton sx={{ padding: '0px' }} onClick={handleMenuOpen}>
                          <MoreVertIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip placement="top" arrow title="Nova tarefa">
                        <IconButton
                          sx={{ padding: '0px' }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setAddingCard(true);
                          }}
                        >
                          <Iconify icon="material-symbols:add-2-rounded" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                  <Menu anchorEl={anchorEl} open={dropdownOpen} onClose={handleMenuClose}>
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenSettingsModal(column.id);
                        handleMenuClose();
                      }}
                      sx={{ color: 'grey.600', display: 'flex', alignItems: 'center' }}
                    >
                      <Iconify icon="solar:settings-bold-duotone" />
                      <Box sx={{ ml: 1 }}>Configurar Ações</Box>
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        clearColumn(column.id);
                        handleMenuClose();
                      }}
                      sx={{ color: 'grey.600', display: 'flex', alignItems: 'center' }}
                    >
                      <Iconify icon="solar:eraser-bold" />
                      <Box sx={{ ml: 1 }}>Limpar coluna</Box>
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteColumn(column.id);
                        handleMenuClose();
                      }}
                      sx={{ color: 'warning.main', display: 'flex', alignItems: 'center' }}
                    >
                      <Iconify icon="solar:archive-down-bold" />
                      <Box sx={{ ml: 1 }}>Arquivar coluna</Box>
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>
              {addingCard && (
                <Container $themeMode={theme.palette.mode}>
                  <Box sx={{ width: '100%', padding: '10px' }}>
                    <KanbanInput
                      ref={addCardInputRef}
                      label="Titulo da tarefa"
                      limit={1000}
                      value={''}
                      onSave={handleCreateCard}
                      onCancel={() => setAddingCard(false)}
                    />
                  </Box>
                </Container>
              )}
              <Droppable droppableId={column.id} index={index} type="TASK" key={index}>
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    sx={{
                      minHeight: '5px',
                      padding: '10px',
                      maxHeight: 'calc(100vh - 300px)',
                      height: 'calc(100vh - 300px)',
                      overflowY: 'auto',
                    }}
                  >
                    {filteredCards.map((card, index) => (
                      <KanbanCard
                        filters={filters}
                        card={card}
                        assigneds={assigneds}
                        priorities={priorities}
                        taskTitle={taskTitle}
                        key={card.id}
                        index={index}
                      />
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </Stack>
          </Paper>
        )}
      </Draggable>
      <KanbanColumnSettingsModal
        kanban={kanban}
        columnId={column.id}
        index={index}
        open={openSettingsModal}
        onClose={setOpenSettingsModal}
      />
    </>
  );
}

const Container = styled(Box)(({ $themeMode }) => ({
  width: 320,
  borderRadius: 12,
  padding: '5px 12px 12px 12px',
  marginBottom: 10,
  fontWeight: 400,
  fontSize: 12,
  backgroundColor: $themeMode === 'light' ? 'rgb(255, 255, 255)' : 'rgb(22, 28, 36)',
  backdropFilter: 'none',
  '&:hover': {
    boxShadow:
      $themeMode === 'light'
        ? 'rgba(145, 158, 171, 0.16) 0px 20px 40px -4px'
        : 'rgba(0, 0, 0, 0.16) 0px 20px 40px -4px',
  },
}));
