import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useTheme, alpha, Typography, GlobalStyles } from '@mui/material';

const customStyles = {
  overlay: { zIndex: 9999 },
};

const getImageSource = (image, key) => {
  const urlMedia = image?.isDrive ? image?.driveUrl : image?.media?.mediaUrl || image?.[key];
  console.info({ urlMedia, image });
  return urlMedia;
}


function LightboxModalStyles() {
  const theme = useTheme();
  const isRTL = theme.direction === 'rtl';
  const ICON_SIZE = 32;
  const ICON_COLOR = theme.palette.grey[600].replace('#', '');
  const iconUrl = (icon) =>
    `url(https://api.iconify.design/carbon/${icon}.svg?color=%23${ICON_COLOR}&width=${ICON_SIZE}&height=${ICON_SIZE})`;

  const Icon = (icon) => ({
    opacity: 1,
    alignItems: 'center',
    display: 'inline-flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    transition: theme.transitions.create('opacity'),
    '&:before': {
      display: 'block',
      width: ICON_SIZE,
      height: ICON_SIZE,
      content: iconUrl(icon),
    },
    '&:hover': { opacity: 0.72 },
  });

  return (
    <GlobalStyles
      styles={{
        '& .ReactModalPortal .ril__outer': {
          backgroundColor: alpha(theme.palette.grey[900], 0.96),
        },
        '& .ReactModalPortal .ril__toolbar': {
          height: 'auto !important',
          padding: theme.spacing(2, 3),
          backgroundColor: 'transparent',
        },
        '& .ReactModalPortal .ril__toolbarLeftSide': { display: 'none' },
        '& .ReactModalPortal .ril__toolbarRightSide': {
          display: 'flex',
          alignItems: 'center',
          flexGrow: 1,
          padding: 0,
          '& li': {
            display: 'flex',
            alignItems: 'center',
          },
          '& li:first-of-type': { flexGrow: 1 },
          '& li:not(:first-of-type)': {
            width: 40,
            height: 40,
            justifyContent: 'center',
            marginLeft: theme.spacing(2),
          },
        },
        '& button:focus': { outline: 'none' },
        '& .ril__toolbarRightSide button': {
          width: '100%',
          height: '100%',
          '&.ril__zoomInButton': Icon('zoom-in'),
          '&.ril__zoomOutButton': Icon('zoom-out'),
          '&.ril__closeButton': Icon('close'),
        },
        '& .ril__navButtons': {
          padding: theme.spacing(3),
          '&.ril__navButtonPrev': {
            left: theme.spacing(2),
            right: 'auto',
            ...Icon(isRTL ? 'arrow-right' : 'arrow-left'),
          },
          '&.ril__navButtonNext': {
            right: theme.spacing(2),
            left: 'auto',
            ...Icon(isRTL ? 'arrow-left' : 'arrow-right'),
          },
        },
      }}
    />
  );
}

export default function LightboxModal({
  images,
  photoIndex,
  setPhotoIndex,
  isOpen,
  ...other
}) {
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset';
  }, [isOpen]);

  const showIndex = useMemo(
    () => (
      <Typography variant="subtitle2">{`${photoIndex + 1} / ${images.length}`}</Typography>
    ),
    [photoIndex, images.length]
  );

  const toolbarButtons = useMemo(() => [showIndex], [showIndex]);

  const prevIndex = (photoIndex + images.length - 1) % images.length;
  const nextIndex = (photoIndex + 1) % images.length;

  return (
    <>
      <LightboxModalStyles />
      {isOpen && (
        <Lightbox
          animationDuration={160}
          mainSrc={getImageSource(images[photoIndex], 'mediaUrl')}
          imageTitle={images[photoIndex]?.mediaName}
          mainSrcThumbnail={getImageSource(images[photoIndex], 'mediaUrl')}
          nextSrc={getImageSource(images[nextIndex], 'mediaUrl')}
          nextSrcThumbnail={getImageSource(images[nextIndex], 'mediaUrl')}
          prevSrc={getImageSource(images[prevIndex], 'mediaUrl')}
          prevSrcThumbnail={getImageSource(images[prevIndex], 'mediaUrl')}
          onMovePrevRequest={() => setPhotoIndex(prevIndex)}
          onMoveNextRequest={() => setPhotoIndex(nextIndex)}
          toolbarButtons={toolbarButtons}
          reactModalStyle={customStyles}
          {...other}
        />
      )}
    </>
  );
}

LightboxModal.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      isDrive: PropTypes.bool,
      driveUrl: PropTypes.string,
      mediaUrl: PropTypes.string,
      mediaName: PropTypes.string,
    })
  ).isRequired,
  photoIndex: PropTypes.number.isRequired,
  setPhotoIndex: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
