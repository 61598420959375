import React from "react";
import { Toaster } from 'react-hot-toast';
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LoggedInLayout from "../layout";
import Calendar from "../pages/Calendar";
import Chat from "../pages/Chat";
import ChatBot from "../pages/ChatBot";
import Companies from "../pages/Company/Companies";
import GeneralSystemReport from "../pages/Company/GeneralSystemReport";
import Overview from "../pages/Company/Overview";
import Connections from "../pages/Connections/";
import Contacts from "../pages/Contacts/";
import Dashboard from "../pages/Dashboard/";
import Disconnect from "../pages/Disconnect";
import Folder from "../pages/Folder";
import Kanban from "../pages/Kanban";
import Keywords from "../pages/Keywords";
import Login from "../pages/Login/";
import MassMessaging from "../pages/MassMessaging/";
import MessagesAPI from "../pages/MessagesAPI/";
import Monitor from "../pages/Monitor";
import Queues from "../pages/Queues/";
import QuickAnswers from "../pages/QuickAnswers/";
import Recovery from "../pages/Recovery";
import RecoveryUser from "../pages/RecoveryUser/";
import ReportTickets from "../pages/ReportTickets";
import ScheduleMessages from "../pages/ScheduleMessages";
import SessionError from "../pages/SessionError";
import Settings from "../pages/Settings/";
import Tags from "../pages/Tags";
import Tickets from "../pages/Tickets/";
import Users from "../pages/Users";
import WebChat from "../pages/WebChat";

import { AuthProvider } from "../context/Auth/AuthContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";

import Route from "./Route";
import KanbanBoard from "../pages/KanbanBoard";


const Routes = () => {

  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          {/*
          <Route exact path="/payment" component={Payment} />
          <Route exact path="/webchat" component={WebChat} />
         */}
          <Route exact path="/login" component={Login} />
          <Route exact path="/recovery" component={Recovery} />
          <Route exact path="/recovery-user/:recovery?/:code?" component={RecoveryUser} />
          <Route exact path="/webchat" component={WebChat} />
          <Route exact path="/disconnect" component={Disconnect} />
          <Route exact path="/monitor" component={Monitor} isPrivate />
          <WhatsAppsProvider>
            <LoggedInLayout>
              <Route exact path="/" component={Dashboard} isPrivate />
              <Route exact path="/tickets/:ticketId?" component={Tickets} isPrivate />
              <Route exact path="/connections" component={Connections} isPrivate />
              <Route exact path="/contacts" component={Contacts} isPrivate />
              <Route exact path="/mass-messaging" component={MassMessaging} isPrivate />
              <Route exact path="/report-tickets" component={ReportTickets} isPrivate />
              <Route exact path='/companies' component={Companies} isPrivate />
              <Route exact path='/chat' component={Chat} isPrivate />
              <Route exact path='/companies/system-overview' component={Overview} isPrivate />
              <Route exact path='/companies/system-report' component={GeneralSystemReport} isPrivate />
              <Route exact path="/users" component={Users} isPrivate />
              <Route exact path="/tags" component={Tags} isPrivate />
              <Route exact path="/kanban" component={Kanban} isPrivate />
              <Route exact path="/kanban/board/:kanbanId?" component={KanbanBoard} isPrivate />
              <Route exact path="/chatbot" component={ChatBot} isPrivate />
              <Route exact path="/quick-answers" component={QuickAnswers} isPrivate />
              <Route exact path="/messages-api" component={MessagesAPI} isPrivate />
              <Route exact path="/settings" component={Settings} isPrivate />
              <Route exact path="/schedules" component={ScheduleMessages} isPrivate />
              <Route exact path="/queues" component={Queues} isPrivate />
              <Route exact path="/keywords" component={Keywords} isPrivate />
              <Route exact path="/folder" component={Folder} isPrivate />
              <Route exact path="/calendar" component={Calendar} isPrivate />
              <Route exact path="/session/error" component={SessionError} isPrivate />
            </LoggedInLayout>
          </WhatsAppsProvider>
        </Switch>
        <ToastContainer autoClose={3000} />
        <Toaster
          position="top-right"
          reverseOrder={false}
        />
      </AuthProvider>
    </BrowserRouter >
  );
};

export default Routes;
