import { Box, Checkbox, Chip, Divider, Grid, Tooltip } from '@mui/material';
import React from 'react';
import { i18n } from '../../translate/i18n';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WatchLaterIcon from '@mui/icons-material/WatchLater';

import { TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ptBR } from 'date-fns/locale';

import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import NoMeetingRoomIcon from '@mui/icons-material/NoMeetingRoom';

const manterHoraLocal = (data) => {
  // Obter componentes de data e hora no fuso local
  const ano = data.getFullYear();
  const mes = String(data.getMonth() + 1).padStart(2, '0'); // Ajustar para base 1
  const dia = String(data.getDate()).padStart(2, '0');
  const hora = String(data.getHours()).padStart(2, '0');
  const minuto = String(data.getMinutes()).padStart(2, '0');
  const segundo = String(data.getSeconds()).padStart(2, '0');

  // Formatar como ISO sem conversão para UTC
  return `${ano}-${mes}-${dia}T${hora}:${minuto}:${segundo}.000Z`;
};

const formatDateValue = (data) => {
  // Garantir que a entrada seja uma string no formato ISO
  const dataString = data instanceof Date ? data.toISOString() : data;

  // Extrair horas e minutos da string
  const [hora, minuto] = dataString.split('T')[1].split(':');

  // Obter a data de hoje
  const hoje = new Date();

  // Criar uma nova data com o dia de hoje e o horário extraído
  return new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate(), parseInt(hora), parseInt(minuto));
};

const QueueScheduleComponent = ({ values, onChangeValue }) => {
  return (
    <Grid container spacing={1}>
      {Object.entries(values).map(([day, { open, start, end, interval, intervalStart, intervalEnd }]) => (
        <React.Fragment key={day}>
          <Box sx={{ width: '100%' }}>
            <Divider>
              <Tooltip
                title={`${
                  !open
                    ? `Abrir ${i18n.t(`queueModal.form.schedules.${day}`)}`
                    : `Fechar ${i18n.t(`queueModal.form.schedules.${day}`)}`
                }`}
              >
                <Checkbox
                  checked={open}
                  icon={<NoMeetingRoomIcon color="error" />}
                  checkedIcon={<MeetingRoomIcon color="success" />}
                  onChange={(e) => {
                    onChangeValue((prevSchedule) => ({
                      ...prevSchedule,
                      [day]: { ...prevSchedule[day], open: !open },
                    }));
                  }}
                />
              </Tooltip>
              <Chip
                color={open ? 'success' : 'error'}
                label={`${i18n.t(`queueModal.form.schedules.${day}`)}`}
                sx={{ borderRadius: '5px' }}
                size="small"
              />
              <Tooltip
                title={`${!interval ? 'Habilitar' : 'Desabilitar'} intervalo ${i18n.t(
                  `queueModal.form.schedules.${day}`
                )}`}
              >
                <Checkbox
                  disabled={!open}
                  checked={interval}
                  icon={<AccessTimeIcon />}
                  checkedIcon={<WatchLaterIcon color={open ? 'success' : ''} />}
                  onChange={(e) => {
                    onChangeValue((prevSchedule) => ({
                      ...prevSchedule,
                      [day]: { ...prevSchedule[day], interval: !interval },
                    }));
                  }}
                />
              </Tooltip>
            </Divider>
          </Box>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid sx={{ paddingTop: '5px' }} item xs={12} sm={interval ? 3 : 6}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                  <TimePicker
                    disabled={!open}
                    label="Horário inicial*"
                    ampm={false}
                    value={formatDateValue(start)}
                    onChange={(newValue) => {
                      onChangeValue((prevSchedule) => ({
                        ...prevSchedule,
                        [day]: { ...prevSchedule[day], start: manterHoraLocal(newValue) },
                      }));
                    }}
                    slotProps={{ textField: { size: 'small', fullWidth: true, margin: 'dense' } }}
                  />
                </LocalizationProvider>
              </Grid>
              {interval && (
                <Grid sx={{ paddingTop: '5px' }} item xs={12} sm={interval ? 3 : 6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                    <TimePicker
                      disabled={!open}
                      label="Inicio Intervalo*"
                      ampm={false}
                      value={formatDateValue(intervalStart)}
                      onChange={(newValue) => {
                        onChangeValue((prevSchedule) => ({
                          ...prevSchedule,
                          [day]: { ...prevSchedule[day], intervalStart: manterHoraLocal(newValue) },
                        }));
                      }}
                      slotProps={{ textField: { size: 'small', fullWidth: true, margin: 'dense' } }}
                    />
                  </LocalizationProvider>
                </Grid>
              )}
              {interval && (
                <Grid sx={{ paddingTop: '5px' }} item xs={12} sm={interval ? 3 : 6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                    <TimePicker
                      disabled={!open}
                      label="Fim Intervalo*"
                      ampm={false}
                      value={formatDateValue(intervalEnd)}
                      onChange={(newValue) => {
                        onChangeValue((prevSchedule) => ({
                          ...prevSchedule,
                          [day]: { ...prevSchedule[day], intervalEnd: manterHoraLocal(newValue) },
                        }));
                      }}
                      slotProps={{ textField: { size: 'small', fullWidth: true, margin: 'dense' } }}
                    />
                  </LocalizationProvider>
                </Grid>
              )}
              <Grid sx={{ paddingTop: '5px' }} item xs={12} sm={interval ? 3 : 6}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                  <TimePicker
                    disabled={!open}
                    label="Horário final*"
                    ampm={false}
                    value={formatDateValue(end)}
                    onChange={(newValue) => {
                      onChangeValue((prevSchedule) => ({
                        ...prevSchedule,
                        [day]: { ...prevSchedule[day], end: manterHoraLocal(newValue) },
                      }));
                    }}
                    slotProps={{ textField: { size: 'small', fullWidth: true, margin: 'dense' } }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default QueueScheduleComponent;
