import React, { useState } from 'react';
import { IconButton, Popover, Button, Typography, Box } from '@mui/material';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';

const DeleteItemButtonModal = ({
    loading,
    values,
    placeholder,
    color,
    title,
    icon,
    text,
    handleDeleteItem
}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleConfirmDelete = () => {
        handleDeleteItem(values);
        handleClose();
    };

    return (
        <>
            <Button
                size="small"
                variant="contained"
                color={color}
                startIcon={icon}
                disabled={loading}
                onClick={handleClick}
            >
                {placeholder}
            </Button>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    sx: {
                        width: 360,
                        padding: 3,
                        borderRadius: 2,
                        boxShadow: 3,
                    },
                }}
            >
                <Typography variant="h6" sx={{ textAlign: 'center', mb: 2, fontWeight: 'bold' }}>
                    <strong>{title}</strong>
                </Typography>
                {text}
                <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <Button
                        onClick={handleClose}
                        variant="outlined"
                        size="small"
                        sx={{ width: '45%' }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleConfirmDelete}
                        variant="contained"
                        color="error"
                        size="small"
                        sx={{ width: '45%' }}
                    >
                        Confirmar
                    </Button>
                </Box>
            </Popover>
        </>
    );
};

export default DeleteItemButtonModal;
