import { format, isSameDay, parseISO } from "date-fns";
import React, { useEffect, useReducer, useRef, useState } from "react";

import AccessTime from '@mui/icons-material/AccessTime';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Block from '@mui/icons-material/Block';
import CachedIcon from '@mui/icons-material/Cached';
import Done from '@mui/icons-material/Done';
import DoneAll from '@mui/icons-material/DoneAll';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  IconButton
} from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { green } from "@mui/material/colors";
import { styled } from '@mui/material/styles';
import clsx from "clsx";
import ReactLoading from 'react-loading';
import { makeStyles } from 'tss-react/mui';
import GoogleDriveIcon from '../../assets/icons/google-drive.png';
import whatsBackgroundDark from "../../assets/wa-background-dark.png";
import whatsBackgroundLight from "../../assets/wa-background.png";
import GetFirstName from "../../helpers/GetFirstName";
import useSettings from '../../hooks/useSettings';
import socket from "../../hooks/useSocket";
import api from "../../services/api";
import ToastError from "../../toast/error/toastError";
import { i18n } from "../../translate/i18n";
import CalendarMessageCard from "../CalendarMessageCard";
import LightboxModal from '../LightboxModal';
import MarkdownWrapper from "../MarkdownWrapper";
import MessageOptionsMenu from "../MessageOptionsMenu";
import ModalImageCors from "../ModalImageCors";
import RenderIconFile from "../RenderIconFile";
import SchedulingCard from "../SchedulingCard";
import VcardArrayPreview from "../VcardArrayPreview";
import VcardPreview from "../VcardPreview";
import "./MessagesList.css";
import RenderAudioFile from "./RenderAudioFile";
import toastError from "../../errors/toastError";

function getUrlTicketPath() {
  var url = window.location.href;
  var partesDaURL = url.split('/');
  var codigo = partesDaURL[partesDaURL.length - 1];
  return codigo;
};

const useStyles = makeStyles()((theme) => {
  return {
    messagesListWrapper: {
      overflow: "hidden",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },
    messagesList: {
      backgroundSize: '370px',
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      padding: "20px 20px 20px 20px",
      overflowY: "scroll",
      '@media (max-width: 600px)': {
        paddingBottom: "90px",
      },
      ...theme.scrollbarStyles,
    },
    circleLoading: {
      position: "absolute",
      opacity: "70%",
      top: 0,
      left: "47%",
      marginTop: 12,
    },
    messageLeftGroup: {
      fontSize: '13px',
      marginLeft: 30,
      marginRight: 20,
      marginTop: 5,
      minWidth: 100,
      maxWidth: 510,
      height: "auto",
      display: "block",
      position: "relative",
      whiteSpace: "pre-wrap",
      alignSelf: "flex-start",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 8,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 5,
      paddingBottom: 0,
    },
    messageLeft: {
      fontSize: '13px',
      marginRight: 20,
      marginTop: 2,
      minWidth: 100,
      maxWidth: 510,
      height: "auto",
      display: "block",
      position: "relative",
      whiteSpace: "pre-wrap",
      alignSelf: "flex-start",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 8,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 5,
      paddingBottom: 0,
    },
    quotedContainerLeft: {
      margin: "-3px -80px 6px -6px",
      overflow: "hidden",
      backgroundColor: "#f0f0f0",
      borderRadius: "7.5px",
      display: "flex",
      position: "relative",
    },
    fileContainerLeft: {
      overflow: "hidden",
      backgroundColor: "#f0f0f0",
      borderRadius: "7.5px",
      display: "flex",
      position: "relative",
    },
    quotedMsg: {
      padding: 10,
      maxWidth: 300,
      height: "auto",
      display: "block",
      whiteSpace: "pre-wrap",
      overflow: "hidden",
    },
    filedMsg: {
      padding: 10,
      maxWidth: 300,
      height: "auto",
      display: "block",
      whiteSpace: "pre-wrap",
      overflow: "hidden",
    },
    quotedSideColorLeft: {
      flex: "none",
      width: "4px",
      backgroundColor: "#6bcbef",
    },
    filedSideColorLeft: {
      flex: "none",
      width: "4px",
      backgroundColor: "#6bcbef",
    },
    messageRight: {
      fontSize: '13px',
      marginLeft: 20,
      marginTop: 2,
      minWidth: 100,
      maxWidth: 610,
      height: "auto",
      display: "block",
      position: "relative",
      whiteSpace: "pre-wrap",
      alignSelf: "flex-end",
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 0,
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 5,
      paddingBottom: 0,
    },
    quotedContainerRight: {
      margin: "-3px -80px 6px -6px",
      overflowY: "hidden",
      backgroundColor: "#cfe9ba",
      borderRadius: "7.5px",
      display: "flex",
      position: "relative",
    },
    fileContainerRight: {
      overflowY: "hidden",
      backgroundColor: "#cfe9ba",
      borderRadius: "7.5px",
      display: "flex",
      position: "relative",
    },
    quotedMsgRight: {
      padding: 10,
      maxWidth: 300,
      height: "auto",
      whiteSpace: "pre-wrap",
    },
    fileMsgRight: {
      padding: 10,
      maxWidth: 300,
      height: "auto",
      whiteSpace: "pre-wrap",
    },
    quotedSideColorRight: {
      flex: "none",
      width: "4px",
      backgroundColor: "#35cd96",
    },
    fileSideColorRight: {
      flex: "none",
      width: "4px",
      backgroundColor: "#35cd96",
    },
    messageContactName: {
      display: "flex",
      color: "#6bcbef",
      fontWeight: 500,
    },
    textContentItem: {
      overflowWrap: "break-word",
      padding: "3px 80px 6px 6px",
    },
    textContentItemEdited: {
      overflowWrap: "break-word",
      padding: "6px 0px 0px 6px",
    },
    textContentItemVcard: {
      overflowWrap: "break-word",
      padding: "3px 6px 6px 6px",
    },
    textContentItemDeleted: {
      fontStyle: "italic",
      color: "rgba(0, 0, 0, 0.36)",
      overflowWrap: "break-word",
      padding: "3px 80px 6px 6px",
    },
    messageMedia: {
      objectFit: "cover",
      width: 250,
      height: 200,
      paddingBottom: '5px',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },
    timestamp: {
      fontSize: 11,
      position: "absolute",
      bottom: 0,
      right: 5,
      color: "#999",
    },
    timestampEdited: {
      fontSize: 11,
      bottom: 0,
      right: 5,
      color: "#999",
    },
    dailyTimestamp: {
      alignItems: "center",
      textAlign: "center",
      alignSelf: "center",
      width: "140px",
      margin: "10px",
      borderRadius: "10px",
    },
    dailyTimestampText: {
      borderRadius: '10px',
      padding: 3,
      alignSelf: "center",
      marginLeft: "0px",
    },
    ackIcons: {
      fontSize: 18,
      verticalAlign: "middle",
      marginLeft: 4,
    },
    deletedIcon: {
      fontSize: 18,
      verticalAlign: "middle",
      marginRight: 4,
    },
    ackDoneAllIcon: {
      color: green[500],
      fontSize: 18,
      verticalAlign: "middle",
      marginLeft: 4,
    },
    downloadMedia: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "inherit",
      padding: '0px 0px 15px 0px',
    },

    driveIcon: {
      display: 'none',
      width: '20px',
      position: 'absolute',
      marginTop: '-38px',
      right: '10px',
    }
  }
});

const reducer = (state, action) => {
  if (action.type === "LOAD_MESSAGES") {
    const messages = action.payload;
    const newMessages = [];

    messages.forEach((message) => {
      const messageIndex = state?.findIndex((m) => m?.id === message?.id);
      if (messageIndex !== -1) {
        state[messageIndex] = message;
      } else {
        newMessages.push(message);
      }
    });

    return [...newMessages, ...state];
  }

  if (action.type === "ADD_MESSAGE") {

    const { payload: newMessage } = action;

    const messageIndex = state?.findIndex((m) => m?.id === newMessage?.id);
    const messageIndexAwait = state?.findIndex((m) => m?.awaitSendUuid === newMessage?.awaitSendUuid && newMessage?.awaitSendUuid != null);

    if (newMessage?.contact?.isGroup) {
      newMessage.ack = 1;
    }

    if (messageIndex !== -1) {
      state[messageIndex] = newMessage;
    } else if (messageIndexAwait !== -1) {
      state[messageIndexAwait] = newMessage;
    } else {
      state.push(newMessage);
    }

    return [...state];
  }


  if (action.type === "UPDATE_MESSAGE") {
    const messageToUpdate = action.payload;

    const messageIndex = state.findIndex((m) => {
      return (m.id === messageToUpdate.id || (m.awaitSendUuid && m.awaitSendUuid === messageToUpdate.awaitSendUuid));
    });

    if (messageIndex !== -1) {
      if (state[messageIndex].isDeleted === false) {
        state[messageIndex] = messageToUpdate;
      }
    }

    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

function removeDuplicates(arr) {
  const seen = new Map();
  return arr.filter((item) => {
    const key = JSON.stringify(item);
    return seen.has(key) ? false : seen.set(key, true);
  });
}

function checkIfUuidExists(uuidToCheck, uuidArray) {
  return uuidArray.some(uuid => uuid === uuidToCheck);
}

const MessagesList = ({ ticket, ticketId, isGroup }) => {
  const { classes } = useStyles();
  const { themeMode } = useSettings();
  const [messagesArray, dispatch] = useReducer(reducer, []);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const lastMessageRef = useRef();
  const [selectedMessage, setSelectedMessage] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const messageOptionsMenuOpen = Boolean(anchorEl);
  const [contactUUIDS, setContactUUIDS] = useState([]);

  const urlTicketPath = getUrlTicketPath();

  const [messagesList, setMessagesList] = useState([]);

  // Função para filtrar e remover duplicatas
  const updateMessagesList = () => {
    const updatedMessages = removeDuplicates(
      messagesArray.filter((message) => {
        const isMatchingUuid = message.ticket.uuid === urlTicketPath;
        const uuidExistsInContact = checkIfUuidExists(message.ticket.uuid, contactUUIDS);
        return isMatchingUuid || uuidExistsInContact;
      })
    );
    setMessagesList(updatedMessages);
  };

  // useEffect para executar apenas quando necessário
  useEffect(() => {
    // Atualiza a lista de mensagens somente se houver mudanças relevantes
    updateMessagesList();
  }, [messagesArray, urlTicketPath, contactUUIDS]);


  // Filtrar e remover duplicatas
  /*
  const messagesList = removeDuplicates(
    messagesArray.filter((message) => {
      const isMatchingUuid = message.ticket.uuid === urlTicketPath;
      const uuidExistsInContact = checkIfUuidExists(message.ticket.uuid, contactUUIDS);
      return isMatchingUuid || uuidExistsInContact;
    })
  );
  */

  const checkTheme = () => {
    var retorno = { backgroundImage: `url("${whatsBackgroundLight}")` };
    switch (themeMode) {
      case 'dark':
        retorno = { backgroundImage: `url("${whatsBackgroundDark}")` };
        break;
      case 'light':
        retorno = { backgroundImage: `url("${whatsBackgroundLight}")` };
        break;
    }
    return retorno
  }

  const isEdited = (message) => {
    if (message.isEdited) {
      if (message.user && message.user.name) {
        return (
          <Tooltip title={message.user.name}>
            <i>{i18n.t("messagesList.others.editedMessage")}&nbsp;{`(${GetFirstName(message.user.name)})`}&nbsp;&nbsp;</i>
          </Tooltip>
        );
      } else {
        return (
          <i>{i18n.t("messagesList.others.editedMessage")}&nbsp;&nbsp;</i>
        );
      }
    }
    return '';
  };


  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [ticketId]);

  const [messagesMedia, setMessagesMedia] = useState([]);

  useEffect(() => {
    messagesList.forEach(msg => {
      const existIndex = messagesMedia.findIndex(e => e?.id === msg?.id);
      if ((msg?.mediaType === 'imageMessage' || msg?.mediaType === 'image')) {
        if (existIndex === -1) {
          setMessagesMedia(oldArray => [...oldArray, msg]);
        } else {
          setMessagesMedia(oldArray => {
            const newArray = [...oldArray];
            newArray[existIndex] = msg;
            return newArray;
          });
        }
      }
    });
  }, [messagesList]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(async () => {
      try {
        if (!ticketId) return;
        if (typeof ticketId !== 'string' && typeof ticketId !== 'number') return;
        setContactUUIDS([]);

        const { data } = await api.get(`/messages/${ticketId}`, {
          params: { pageNumber },
        });

        const { data: uuids } = await api.get(`/tickets_uuid/${ticket.contactId}`);
        setContactUUIDS(uuids)
        if (ticket?.id === ticketId) {
          dispatch({ type: 'LOAD_MESSAGES', payload: data.messages });
          setHasMore(data.hasMore);
        }

        if (pageNumber === 1) {
          scrollToBottom();
        }
      } catch (err) {
        ToastError(err);
      } finally {
        setLoading(false);
      }
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [pageNumber, ticketId, ticket]);


  useEffect(() => {

    const userCompany = ticket.companyId;

    if ((ticketId !== ticket?.id)) return;

    const handleConnect = () => {
      socket.emit("joinChatBox", `${ticket.id}`);
    };

    const handlePreMessage = (data) => {

      const uuidUrl = getUrlTicketPath();

      if (data?.uuid !== uuidUrl) return;

      const dateActual = new Date().toISOString();
      const message = {
        id: `await_${data?.messageUUID}`,
        ack: 0,
        body: data.body,
        fromMe: true,
        quotedMsg: data?.reqBody?.quotedMsg,
        timestamp: String(Date.now()),
        isDeleted: false,
        mediaName: null,
        mediaType: data?.mediaType ?? "extendedTextMessage",
        mediaUrl: data?.mediaUrl ?? null,
        participant: "",
        ticket: data.ticket,
        createdAt: dateActual,
        updatedAt: dateActual,
        awaitSendUuid: data?.messageUUID,
        mediaId: data?.mediaId ?? null,
        media: data.media ?? null,
      };
      dispatch({ type: "ADD_MESSAGE", payload: message });
      scrollToBottom();
    };


    const handleAppMessage = (data) => {

      const uuidUrl = getUrlTicketPath();

      const { ticketId, ticket: { id: IdInTicket, uuid } = {}, action, message } = data || {};

      if (uuidUrl !== uuid) return;

      const isTicketIdValid = !!ticketId && ticketId === ticket?.id;

      const isIdInTicketValid = !!IdInTicket && IdInTicket === ticket?.id;

      if (isTicketIdValid || isIdInTicketValid) {
        if (action === "create") {
          dispatch({ type: "ADD_MESSAGE", payload: message });
          scrollToBottom();
        } else if (action === "update") {
          dispatch({ type: "UPDATE_MESSAGE", payload: message });
        };
      };

    };


    socket.on("connect", handleConnect);
    if (ticket?.id) {
      socket.on(`preMessage-${ticket.id}`, handlePreMessage)
    };

    socket.on(`appMessage-${userCompany}`, handleAppMessage);

    return () => {
      // Desconectar ouvintes socket (se necessário)
      // socket.off("connect");
      // socket.off(`preMessage-${ticket?.id}`);
      // socket.off(`appMessage-${userCompany}`);
    };

  }, [ticket]);


  const scrollToBottom = () => {
    setTimeout(() => {
      var element = document.getElementById("messagesList");
      if (element) {
        element.scrollTop = element.scrollHeight;
      }
    }, 1);
  };

  const loadingMore = (e) => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const handleOpenMessageOptionsMenu = (e, message) => {
    setAnchorEl(e.currentTarget);
    setSelectedMessage(message);
  };

  const handleCloseMessageOptionsMenu = (e) => {
    setAnchorEl(null);
  };

  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);

  const handleOpenLightbox = (blob, url, isDrive) => {
    try {
      let findUrl;

      if (isDrive || message?.media) {
        findUrl = messagesMedia.findIndex(
          (message) => { return message?.driveUrl === url || message?.media?.mediaUrl === url }
        );
      } else {
        findUrl = messagesMedia.findIndex(
          (message) => message.mediaUrl?.includes(url)
        );
      }

      if (findUrl === -1) {
        console.error('URL não encontrada nos dados fornecidos.');
        toastError('Erro: Não foi possível localizar a mídia solicitada.');
        return; // Não prossegue se não encontrar a URL.
      }

      setOpenLightbox(true);
      setSelectedImage(findUrl);
    } catch (error) {
      console.error('Erro ao tentar abrir o lightbox:', error);
      toastError('Ocorreu um erro ao tentar abrir a mídia. Tente novamente.');
    }
  };

  const getColorToChatQuoted = (fromMe, themeColor, isSchedule = false) => {
    let color = '';
    if (fromMe) {
      if (themeColor === 'dark') { color = isSchedule ? '#212b36' : '#025144' }
      else { color = isSchedule ? '#fff' : '#cfe9ba' }
    } else {
      if (themeColor === 'dark') { color = '#1d282f' }
      else { color = '#f0f0f0' }
    }
    return `${color} !important`;
  }

  const getColorToChatFile = (fromMe, themeColor) => {
    let color = '';
    if (fromMe) {
      if (themeColor === 'dark') { color = '#fff' }
      else { color = '#212b36' }
    } else {
      if (themeColor === 'dark') { color = '#fff' }
      else { color = '#212b36' }
    }
    return `${color} !important`;
  }

  const renderFile = (message) => {
    // Verifica se mediaUrl existe e não é null antes de tentar usar o split
    const fileName = message?.media?.mediaName || message?.fileName || (message.mediaUrl ? message.mediaUrl.split("/").pop() : null);

    // Verifica se fileName foi atribuído corretamente antes de usar o split novamente
    const fileType = fileName ? fileName.split(".").pop() : null;

    return (

      <Box component="div"
        sx={{ backgroundColor: (theme) => getColorToChatQuoted(message.fromMe, theme.palette.mode, message.mediaType === "schedule") }}
        className={clsx(classes.fileContainerLeft, {
          [classes.fileContainerRight]: message.fromMe,
        })}
      >
        <span
          className={clsx(classes.fileSideColorLeft, {
            [classes.fileSideColorRight]: message?.fromMe,
          })}
        ></span>
        <Box component="div" className={classes.fileMsg} >
          <Box component="span">
            <div style={{ display: 'flex', width: '255px', padding: '5px', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>{
                  <RenderIconFile type={fileType}
                    style={{
                      width: '45px',
                      height: '40px'
                    }}
                  />
                }</div>
                <div>
                  <p
                    style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      width: '140px'
                    }}
                  >{fileName || message.body}</p>
                  <small>{fileType}</small>
                </div>
                <div>
                </div>
              </div>
              <div>
                <IconButton
                  sx={{ color: (theme) => getColorToChatFile(message.fromMe, theme.palette.mode) }}
                  variant="text"
                  target="_blank"
                  href={message?.media ? message.media.mediaUrl : message?.isDrive ? message.driveUrl : message.mediaUrl}
                >
                  <DownloadForOfflineIcon />
                </IconButton >
              </div>
            </div>
          </Box>
        </Box>
      </Box >
    );
  };

  const checkMessageMedia = (message, isSchedule = false) => {
    if (message.mediaType === "locationMessage" || message.mediaType === 'liveLocationMessage') {
      const data = JSON.parse(message.mediaUrl.split("public/")[1])
      return <div>
        <iframe
          width="500"
          height="200"
          style={{ borderColor: '#ff000000' }}
          src={`https://maps.google.com/maps?q=${data.degreesLatitude},${data.degreesLongitude}&t=&z=15&ie=UTF8&iwloc=&output=embed `} />
      </div>;
    }
    if (message.mediaType == "image" || message.mediaType == "imageMessage") {
      return <div>
        <ModalImageCors dataMessage={message} isDrive={message?.isDrive || message?.media?.mediaUrl} onOpenLightbox={handleOpenLightbox} />
      </div>;
    }
    if (message.mediaType == "audio" || message.mediaType == "audioMessage") {
      return (
        <>
          <RenderAudioFile
            themeMode={themeMode}
            srcAudio={message?.media ? message.media.mediaUrl : message?.isDrive ? message.driveUrl : message.mediaUrl}
            colors={themeMode === 'dark' ? '#202c33' : '#ffffff'}
            idDiv={Math.floor(Math.random() * 9999999999) + 1}
            avatarUser={message?.contact?.profilePicUrl}
          />
        </>
      );
    }
    if (message.mediaType === "video" || message.mediaType === "videoMessage") {
      return (<>
        <video
          className={classes.messageMedia}
          src={message?.media ? message.media.mediaUrl : message?.isDrive ? message.driveUrl : message.mediaUrl}
          controls
        />
      </>
      );
    } else {
      return (
        <>
          <div className={classes.downloadMedia}>
            <div>{renderFile(message)}</div>
          </div>
        </>
      );
    }
  };

  const renderMessageAck = (message) => {

    if (message.ack === 0) {
      return <AccessTime fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 1) {
      return <Done fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 2) {
      return <DoneAll fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 3 || message.ack === 4) {
      return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
    }
    if (message.ack === 7) {
      return <ScheduleSendIcon fontSize="small" className={classes.ackIcons} />;
    }
  };

  const renderDailyTimestamps = (message, index) => {
    if (index === 0) {
      return (
        <span
          className={classes.dailyTimestamp}
          key={`timestamp-${message.id}`}
        >
          <Box component="div" sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#202c33' : '#fefefe', boxShadow: 2 }} className={classes.dailyTimestampText}>
            {/*dateCalculate.getDayOfMessage(format(parseISO(message?.createdAt), "yyyy-MM-dd"))*/}
            {(format(parseISO(message?.createdAt), "dd-MM-yyyy"))}
          </Box>
        </span>
      );
    }
    if (index < messagesList.length - 1) {
      let messageDay = parseISO(messagesList[index].createdAt || new Date());
      let previousMessageDay = parseISO(messagesList[index - 1]?.createdAt || new Date());

      if (!isSameDay(messageDay, previousMessageDay)) {
        return (
          <span
            className={classes.dailyTimestamp}
            key={`timestamp-${message.id}`}
          >
            <Box component="div" sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#202c33' : '#fefefe', boxShadow: 2 }} className={classes.dailyTimestampText}>
              {/*dateCalculate.getDayOfMessage(format(parseISO(message?.createdAt), "yyyy-MM-dd"))*/}
              {(format(parseISO(message?.createdAt), "dd-MM-yyyy"))}
            </Box>
          </span>
        );
      }
    }
    if (index === messagesList.length - 1) {
      return (
        <div
          key={`ref-${message.createdAt}`}
          ref={lastMessageRef}
          style={{ float: "left", clear: "both" }}
        />
      );
    }
  };

  const renderNumberTicket = (message, index) => {
    if (index < messagesList.length && index > 0) {
      let messageTicket = message.ticketId;
      let previousMessageTicket = messagesList[index - 1].ticketId;

      if (messageTicket !== previousMessageTicket) {
        return (
          <div key={`ticket-${message.id}`} >
            <Divider><Chip variant="outlined" size="small" label={`#${messageTicket}`} /></Divider>
          </div>
        );
      }
    }
  };

  const renderMessageDivider = (message, index) => {
    if (index < messagesList.length && index > 0) {
      let messageUser = messagesList[index].fromMe;
      let previousMessageUser = !!messagesList[index - 1]?.fromMe;
      if (messageUser !== previousMessageUser) {
        return (
          <span style={{ marginTop: 16 }} key={`divider-${message.id}`}></span>
        );
      }
    }
  };

  const getMessage = (id) => {
    var elmntToView = document.getElementById(id);
    if (elmntToView) {
      elmntToView.scrollIntoView({ behavior: "smooth" });
    }
  }

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 350,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  const renderQuotedMessage = (message) => {
    return (
      <Box component="div"
        sx={{ backgroundColor: (theme) => getColorToChatQuoted(message.fromMe, theme.palette.mode) }}
        className={clsx(classes.quotedContainerLeft, {
          [classes.quotedContainerRight]: message.fromMe,
        })}
      >
        <span
          className={clsx(classes.quotedSideColorLeft, {
            [classes.quotedSideColorRight]: message.quotedMsg?.fromMe,
          })}
        ></span>
        <Box component="div" className={classes.quotedMsg} onClick={e => getMessage(message.quotedMsgId)}>
          {!message.quotedMsg?.fromMe && (
            <Box component="span" className={classes.messageContactName}>
              {message.quotedMsg?.contact?.name}
            </Box>
          )}
          <Box component="span"><MarkdownWrapper>{message.quotedMsg?.body}</MarkdownWrapper></Box>
        </Box>
      </Box>
    );
  };

  const extractContact = (array) => {

    let contactNumber = "";
    let contactName = "";

    array.forEach(item => {
      const [key, value] = item.split(":");
      if (key.includes('TEL')) {
        contactNumber = value;
      }
      if (key === "FN") {
        contactName = value;
      }
    });

    return { contactName, contactNumber };
  }

  const renderContact = (message) => {
    let array = [];

    if (message.body.includes('VCARD')) {
      array = message.body.split("\n");
    } else if (message.dataJson) {
      const dataJson = JSON.parse(message.dataJson);
      array = dataJson?.message?.extendedTextMessage?.text.split("\n") || [];
    }

    let contactMessage;
    if (message.mediaType == "contactsArrayMessage") {
      const contactsArray = JSON.parse(message.body);
      contactMessage = <VcardArrayPreview contacts={contactsArray} />;
    } else {
      const { contactName, contactNumber } = extractContact(array);
      contactMessage = <VcardPreview contact={contactName} numbers={contactNumber} />;
    }
    return contactMessage;

  };

  const renderMessages = () => {
    if (messagesList.length > 0) {
      const viewMessagesList = messagesList.map((message, index) => {
        if (!message) return;
        let isContact = message?.mediaType == 'contactMessage' || message?.mediaType == 'contactsArrayMessage';
        if (!message?.fromMe) {
          return (
            <React.Fragment key={message.id}>
              {renderMessageDivider(message, index)}
              {renderNumberTicket(message, index)}
              {renderDailyTimestamps(message, index)}
              <>
                {message.mediaType == "call_log" ?
                  <React.Fragment key={message.id}>
                    <Divider sx={{ padding: '15px 0px' }}>
                      <Chip label={message.body} size="small" />
                    </Divider>
                  </React.Fragment>
                  :
                  <>
                    {isGroup ? (
                      <>
                        <Box component="div" id={message.id} className={`${classes.messageLeftGroup} componentChat`} sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#202c33' : '#ffffff' }}>
                          <div style={{ position: 'absolute', marginLeft: '-40px' }}>
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  <div>
                                    <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                                      <Avatar sx={{ width: 80, height: 80 }} src={message.contact?.profilePicUrl} />
                                    </div>
                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                      <ListItem>
                                        <div>
                                          <ListItemText
                                            primary={message.contact?.name}
                                            secondary={
                                              <React.Fragment>
                                                <Typography
                                                  sx={{ display: 'inline' }}
                                                  component="span"
                                                  variant="body2"
                                                  color="text.primary"
                                                >
                                                  Criado em: &nbsp;
                                                </Typography>
                                                {format(parseISO(message.contact?.createdAt), "dd/MM/yyyy HH:mm")}
                                              </React.Fragment>
                                            }
                                          />
                                        </div>
                                      </ListItem>
                                      <Divider variant="inset" component="li" />
                                      <ListItem>
                                        <ListItemAvatar>
                                          <Avatar>
                                            <LocalPhoneIcon />
                                          </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Número" secondary={message.contact?.number} />
                                      </ListItem>
                                      {message.contact?.email && <>
                                        <Divider variant="inset" component="li" />
                                        <ListItem>
                                          <ListItemAvatar>
                                            <Avatar>
                                              <EmailIcon />
                                            </Avatar>
                                          </ListItemAvatar>
                                          <ListItemText primary="E-Mail" secondary={message.contact?.email} />
                                        </ListItem>
                                      </>}
                                    </List>
                                  </div>
                                </React.Fragment>
                              }
                            >
                              <Avatar
                                alt={message.contact?.name}
                                src={message.contact?.profilePicUrl}
                                sx={{ width: 30, height: 30 }}
                              />
                            </HtmlTooltip>
                          </div>
                          <div>
                            <IconButton
                              variant="contained"
                              size="small"
                              id="messageActionsButton"
                              disabled={message.isDeleted}
                              style={{
                                display: "flex",
                                position: "absolute",
                                top: -4,
                                right: 0,
                                color: "#999",
                                zIndex: 1,
                                opacity: "90%",
                              }}
                              onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                            >
                              <ArrowDropDownIcon />
                            </IconButton>
                            <div>
                              <span className={classes.messageContactName}>
                                {message.contact?.name}
                              </span>
                            </div>
                            {message.mediaUrl && checkMessageMedia(message)}
                            <div className={!isContact ? message.isEdited ? classes.textContentItemEdited : classes.textContentItem : classes.textContentItemVcard}>
                              {message.quotedMsg && renderQuotedMessage(message)}
                              {message.body.includes("VCARD") && renderContact(message)}
                              {!message?.mediaUrl?.includes(message.body) && !message.body?.includes("VCARD") && message.mediaType !== "audio" && message.mediaType !== "audioMessage" &&
                                <p><MarkdownWrapper>{message.body}</MarkdownWrapper></p>}
                              <span className={message.isEdited ? classes.timestampEdited : classes.timestamp}>
                                {isEdited(message)}{format(parseISO(message.createdAt), "HH:mm")}
                              </span>
                            </div>
                          </div>
                        </Box>
                        {/*message?.mediaType === "buttonsMessage" &&
                      <Box component="div" className={`${classes.messageLeftGroup} componentChat`} sx={{ backgroundColor: '#ffffff00', width: 490 }}>
                        {renderButtons(message?.dataJson, message.id)}
                      </Box>*/}
                      </>
                    ) : (
                      <>
                        <Box component="div" id={message.id} className={`${classes.messageLeft} componentChat`} sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#202c33' : '#ffffff' }}>
                          <IconButton
                            variant="contained"
                            size="small"
                            id="messageActionsButton"
                            disabled={message.isDeleted}
                            style={{
                              display: "flex",
                              position: "absolute",
                              top: -4,
                              right: 0,
                              color: "#999",
                              zIndex: 1,
                              opacity: "90%",
                            }}
                            onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                          >
                            <ArrowDropDownIcon />
                          </IconButton>
                          {message.mediaUrl && checkMessageMedia(message)}
                          <div className={!isContact ? message.isEdited ? classes.textContentItemEdited : classes.textContentItem : classes.textContentItemVcard}>
                            {message.quotedMsg && renderQuotedMessage(message)}
                            {message.body.includes("VCARD") && renderContact(message)}
                            {!message?.mediaUrl?.includes(message.body) && !message.body?.includes("VCARD") && message.mediaType !== "audio" && message.mediaType !== "audioMessage" && <p><MarkdownWrapper>{message.body}</MarkdownWrapper></p>}

                            <span className={message.isEdited ? classes.timestampEdited : classes.timestamp}>
                              {isEdited(message)}{format(parseISO(message.createdAt), "HH:mm")}
                            </span>
                          </div>
                        </Box>
                        {/*message?.mediaType === "buttonsMessage" &&
                      <Box component="div" className={`${classes.messageLeft} componentChat`} sx={{ backgroundColor: '#ffffff00', width: 490 }}>
                        {renderButtons(message?.dataJson, message.id)}
                      </Box>*/}
                      </>)
                    }
                  </>}
              </>

            </React.Fragment >
          );
        } else {
          return (
            <React.Fragment key={message.id} >
              {renderMessageDivider(message, index)}
              {renderDailyTimestamps(message, index)}
              {message.mediaType === "notepad" ?
                <>
                  <Box component="div" id={message.id} className={`${classes.messageRight} componentChat`} sx={{ fontStyle: 'italic', backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#e5e5e5' : '#feffd3' }}>
                    <div
                      className={!isContact ? message.isEdited ? classes.textContentItemEdited : classes.textContentItem : classes.textContentItemVcard}
                    >
                      <Box component="div" sx={{ color: (theme) => theme.palette.mode === 'dark' ? '#3c3c3c' : '#212b36' }}>
                        <p><MarkdownWrapper>{message.body}</MarkdownWrapper></p>
                      </Box>
                      <span className={message.isEdited ? classes.timestampEdited : classes.timestamp}>
                        {isEdited(message)}{format(parseISO(message.createdAt), "HH:mm")}
                        <NoteAltIcon style={{ margin: '-5px 5px', width: 20 }} />
                      </span>
                    </div>
                  </Box>
                </>
                : !!message.scheduleId ? (
                  <Box component="div" id={message.id} className={`${classes.messageRight} componentChat`}>
                    <SchedulingCard
                      scheduleId={message.schedule}
                      message={message}
                      mediaComponent={checkMessageMedia(message, true)}
                      ack={renderMessageAck(message)}
                    />
                  </Box>
                )
                  : !!message.calendarId ? (
                    <Box component="div" id={message.id} className={`${classes.messageRight} componentChat`}>
                      <CalendarMessageCard
                        calendarId={message.calendarId}
                        message={message}
                        // mediaComponent={checkMessageMedia(message, true)}
                        ack={renderMessageAck(message)}
                      />
                    </Box>
                  )
                    :
                    <>
                      <Box component="div" id={message.id} className={`${classes.messageRight} componentChat`} sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#005c4b' : '#d9fdd3' }}>
                        <IconButton
                          variant="contained"
                          size="small"
                          id="messageActionsButton"
                          disabled={message.isDeleted}
                          style={{
                            display: "flex",
                            position: "absolute",
                            top: -4,
                            right: 0,
                            color: "#999",
                            zIndex: 1,
                            opacity: "90%",
                          }}
                          onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                        >
                          <ArrowDropDownIcon />
                        </IconButton>
                        {(message.mediaUrl || message?.media) && checkMessageMedia(message)}
                        <div
                          className={!isContact ? message.isEdited || message?.isSendInApi ? classes.textContentItemEdited : classes.textContentItem : classes.textContentItemVcard}
                        >
                          {message.isDeleted && (
                            <Block
                              color="disabled"
                              fontSize="small"
                              className={classes.deletedIcon}
                            />
                          )}
                          {message.quotedMsg && renderQuotedMessage(message)}
                          {
                            (message?.body?.includes("VCARD") || Array.isArray(message?.dataJson) && message?.dataJson?.includes('VCARD')) && renderContact(message)
                          }
                          {!message?.mediaUrl?.includes(message.body) && !message.body?.includes("VCARD") && message.mediaType !== "audio" && message.mediaType !== "audioMessage" && !message.mediaUrl && <p><MarkdownWrapper>{message.body}</MarkdownWrapper></p>}
                          <span className={message.isEdited || message?.isSendInApi ? classes.timestampEdited : classes.timestamp}>
                            {isEdited(message)}
                            {message?.isSendInApi && 'Via API - '}
                            {format(parseISO(message.createdAt), "HH:mm")}
                            {renderMessageAck(message)}
                          </span>
                        </div>
                      </Box>
                    </>
              }
            </React.Fragment >
          );
        }
      });
      return viewMessagesList;
    } else {
      return <div>Say hello to your new contact!</div>;
    }
  };

  return (
    <>
      <LightboxModal
        id="messageMediaModal"
        images={messagesMedia}
        mainSrc={messagesMedia[selectedImage]}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onCloseRequest={() => setOpenLightbox(false)}
      />
      <div className={classes.messagesListWrapper}>
        <MessageOptionsMenu
          message={selectedMessage}
          anchorEl={anchorEl}
          menuOpen={messageOptionsMenuOpen}
          handleClose={handleCloseMessageOptionsMenu}
        />
        <div
          id="messagesList"
          className={classes.messagesList}
          style={checkTheme()}
        // onScroll={handleScroll}
        >
          {messagesList.length > 0 && hasMore && !loading ? <>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <IconButton
                onClick={() => { loadingMore() }}
                sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#212b36' : '#ffffff' }}
                color="secondary">
                <CachedIcon />
              </IconButton>
            </Box>
          </> : ''}
          {loading && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box
                as={ReactLoading}
                sx={{ fill: (theme) => `${theme.palette.primary.main} !important` }}
                type={'spin'}
                style={{
                  height: '40px',
                  width: '40px'
                }} />
            </Box>
          )}
          {messagesList.length > 0 ? renderMessages() : []}
        </div>
      </div>
    </>
  );
};

export default MessagesList;
