import * as React from 'react';
import { Stack, Avatar, Typography, ListItem, Box, Divider } from '@mui/material';
import { format, isToday, isYesterday, formatDistanceToNow } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR'; // Importa o locale em português
import RenderMarkDownMessage from '../RenderMarkdownMessage';
import KanbanCardCommentsMediasPreviews from '../KanbanCardCommentsMediasPreviews';

const CustomListItem = ({ comment, divider }) => {
    const formatDate = (date) => {
        const now = new Date();

        // Verifica se a data está dentro de 5 minutos
        if (date > new Date(now - 5 * 60 * 1000)) {
            return formatDistanceToNow(date, { addSuffix: true, locale: ptBR });
        }

        // Verifica se é hoje
        if (isToday(date)) {
            return `Hoje às ${format(date, 'HH:mm')}`;
        }

        // Verifica se foi ontem
        if (isYesterday(date)) {
            return `Ontem às ${format(date, 'HH:mm')}`;
        }

        // Formatação padrão para outras datas
        return format(date, 'dd/MM/yyyy HH:mm', { locale: ptBR });
    };

    return (
        <ListItem disableGutters>
            <Stack direction="row" spacing={2} alignItems="flex-start" sx={{ width: '100%' }}>
                <Avatar
                    alt="user_pic"
                    src={comment?.user?.media}
                    sx={{ width: 35, height: 35 }}
                />
                <Stack spacing={1}
                    sx={{
                        width: '100%',
                        backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#2f3641' : '#edeff1',
                        padding: '10px',
                        borderRadius: '0px 5px 5px 5px',
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="subtitle2">{comment?.user?.name}</Typography>
                        <Typography variant="caption" color="text.secondary">
                            {formatDate(new Date(comment.createdAt))}
                        </Typography>
                    </Box>
                    <RenderMarkDownMessage>
                        {comment?.text}
                    </RenderMarkDownMessage>
                    {comment?.medias?.length > 0 && (
                        <KanbanCardCommentsMediasPreviews medias={comment.medias} />
                    )}
                    {/*
                    <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                        {comment?.text}
                    </Typography>
                   */}
                </Stack>
            </Stack>
            {divider && <Divider />}
        </ListItem>
    );
};

export default CustomListItem;
