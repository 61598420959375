import React from 'react';
import { Tabs, Tab, Box } from '@mui/material';

const KanbanCardTabs = ({ value, onChange }) => {

    const handleChange = (event, newValue) => { onChange(newValue) };

    return (
        <Box sx={{ marginBottom: 0, backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#2f3641' : '#edeff1', padding: '5px 20px' }}>
            <Tabs
                centered
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                sx={{ minHeight: "35px", height: "35px" }}
                TabIndicatorProps={{
                    style: { display: 'none' }
                }}
                textColor="inherit"
            >
                <Tab label="Geral"
                    sx={{
                        height: '35px',
                        minHeight: '35px',
                        borderRadius: '10px',
                        ...(value === 0 && {
                            backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#1b212b' : '#fff',
                        })
                    }} />
                <Tab label="Subtarefas"
                    sx={{
                        height: '35px',
                        minHeight: '35px',
                        borderRadius: '10px',
                        ...(value === 1 && {
                            backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#1b212b' : '#fff',
                        })
                    }}
                />
                {/*
                <Tab size="small"
                    sx={{
                        height: '35px',
                        minHeight: '35px',
                        borderRadius: '10px',
                        ...(value === 2 && {
                            backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#1b212b' : '#fff',
                        })
                    }}
                    label="Comentarios"
                /> 
                */}
            </Tabs>
        </Box>
    );
};

export default KanbanCardTabs;
