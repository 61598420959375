import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";

import {
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	CircularProgress,
} from "@mui/material";

import { i18n } from "../../translate/i18n";
import { makeStyles } from 'tss-react/mui';
import UserAutoComplete from "../UserAutoComplete";
import api from "../../services/api";
import ToastError from "../../toast/error/toastError";

const useStyles = makeStyles()((theme) => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},
	extraAttr: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	btnWrapper: {
		position: "relative",
	},
	buttonProgress: {
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));

const initialLoading = { submit: false, users: false };

const CreateOrEditKanbanModal = ({ open, onClose, kanbanId }) => {
	const { classes } = useStyles();

	const [users, setUsers] = useState([]);

	const [selectedsUsers, setSelectedsUsers] = useState([]);

	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [taskTitle, setTaskTitle] = useState('chamado');

	const [loading, setLoading] = useState(initialLoading);

	const handleClose = () => {
		setTitle('');
		setDescription('');
		setUsers([]);
		setSelectedsUsers([]);
		setLoading(initialLoading)
		onClose(false);
	};

	const handleConfirm = async () => {
		// Função de validação
		const validateForm = () => {
			if (!title || title.length < 2) {
				ToastError("Defina um título para este quadro");
				return false;
			}
			if (selectedsUsers.length === 0) {
				ToastError("Selecione pelo menos um usuário para atribuir");
				return false;
			}
			return true;
		};

		if (!validateForm()) return;

		setLoading((prev) => ({ ...prev, submit: true }));

		const payload = {
			title,
			description,
			taskTitle,
			assigneds: selectedsUsers.map(user => user.id),
		};

		const apiMethod = kanbanId ? api.put : api.post;
		const apiUrl = kanbanId ? `/kanban/${kanbanId}` : '/kanban/';

		try {
			await apiMethod(apiUrl, payload);
			handleClose();
		} catch (error) {
			console.error("Erro ao salvar o Kanban:", error);
			ToastError("Ocorreu um erro ao salvar o Quadro");
		} finally {
			setLoading((prev) => ({ ...prev, submit: false }));
		}
	};


	const handleNewListOfAssigned = async (assignedsUsers) => {
		const oldAssigneds = selectedsUsers;
		console.info({ oldAssigneds })
		try {
			setSelectedsUsers(assignedsUsers);
		} catch (err) {
			setSelectedsUsers(oldAssigneds);
		}
	};

	useEffect(() => {
		if (!open) return;

		(async () => {
			try {
				setLoading((prev) => ({ ...prev, users: true }));
				const { data } = await api.get("/users-list/", {
					params: { searchParam: "", limit: 300, pageNumber: 1, isActive: "true", getAllUsers: "false" },
				});
				setUsers(data.users)
			} catch (err) {
				console.error(err)
			} finally {
				setLoading((prev) => ({ ...prev, users: false }));
			}
		})();

		if (kanbanId) {
			(async () => {
				try {
					setLoading((prev) => ({ ...prev, submit: true }));
					const { data } = await api.get(`/kanban/${kanbanId}`);
					const { title, description, taskTitle } = data;
					setTitle(title);
					setDescription(description);
					setTaskTitle(taskTitle);
					if (data?.assigneds) { setSelectedsUsers(data.assigneds) };

				} catch (err) {
					console.error(err)
				} finally {
					setLoading((prev) => ({ ...prev, submit: false }));
				}
			})();
		}

	}, [open, kanbanId]);

	return (
		<Dialog
			open={open}
			onClose={() => handleClose()}
			aria-labelledby="confirm-dialog"
			BackdropProps={{
				style: {
					backdropFilter: 'blur(3px)',
					backgroundColor: 'rgba(0, 0, 30, 0.4)',
				},
			}}
		>
			<DialogTitle id="confirm-dialog">
				{kanbanId
					? `${i18n.t("kanban.modal.title.edit")}`
					: `${i18n.t("kanban.modal.title.create")}`
				}
			</DialogTitle>
			<DialogContent dividers>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={7}>
						<TextField
							size="small"
							disabled={loading.submit}
							fullWidth
							label={i18n.t("kanban.modal.field.title")}
							value={title}
							onChange={(e) => setTitle(e.target.value)}
						/>
					</Grid>
					<Grid item xs={12} sm={5}>
						<TextField
							size="small"
							disabled={loading.submit}
							fullWidth
							label={i18n.t("kanban.modal.field.taskName")}
							value={taskTitle}
							helperText="Ex: protocolo, chamado e etc..."
							onChange={(e) => setTaskTitle(e.target.value.toLowerCase())}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							size="small"
							disabled={loading.submit}
							fullWidth
							label={i18n.t("kanban.modal.field.description")}
							multiline
							minRows={2}
							maxRows={8}
							value={description}
							onChange={(e) => setDescription(e.target.value)}
						/>
					</Grid>
					<Grid item xs={12}>
						<UserAutoComplete
							labels={users}
							placeholder="Usuários atribuidos"
							search="Busque para atribuir"
							value={selectedsUsers}
							setValue={(values) => handleNewListOfAssigned(values)}
							loading={loading.users}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" onClick={() => handleClose()} color="error">
					{i18n.t("kanban.modal.buttons.cancel")}
				</Button>
				<Button
					type="submit"
					color="primary"
					disabled={loading.submit}
					variant="contained"
					onClick={handleConfirm}
					className={classes.btnWrapper}
				>
					{kanbanId
						? `${i18n.t("kanban.modal.buttons.edit")}`
						: `${i18n.t("kanban.modal.buttons.save")}`}
					{loading.submit && (
						<CircularProgress
							size={24}
							className={classes.buttonProgress}
						/>
					)}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default CreateOrEditKanbanModal;
