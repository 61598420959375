import SaveIcon from '@mui/icons-material/Save';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import React, { forwardRef, useEffect, useState, useRef } from 'react';
import { keyframes } from '@mui/system';
import { Box, CircularProgress } from '@mui/material';

const shake = keyframes`
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
`;

const KanbanInput = forwardRef(({ label, value, onSave, onCancel, fontSize, limit, ...props }, ref) => {
    const [inputValue, setInputValue] = useState('');
    const [isShaking, setIsShaking] = useState(false);
    const paperRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        setInputValue(value || '');
    }, [value]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (paperRef.current && !paperRef.current.contains(event.target)) {
                if (inputValue.trim() !== value?.trim()) {
                    handleSave();
                } else {
                    onCancel();
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [inputValue, value, onCancel]);

    const handleSave = () => {
        if (inputValue.trim() === value?.trim()) {
            return;
        }

        if (inputValue.trim() === '' && inputValue.trim() !== value?.trim()) {
            console.warn('Input cannot be empty');
            onCancel();
            return;
        }

        onSave(inputValue);
        setInputValue('');
    };

    const isExceeded = limit ? inputValue.length >= limit : false;

    const handleChange = (e) => {
        const newValue = e.target.value;
        if (isExceeded && newValue.length > inputValue.length) {
            setIsShaking(true);
            setTimeout(() => setIsShaking(false), 1000);
            return;
        }
        setInputValue(newValue);
    };

    const handleBlur = () => {
        handleSave();
    };

    const handleKeyDown = (event) => {
        // Verifica se a tecla pressionada é Enter
        if (event.key === 'Enter') {
            // Se for Enter sem Shift, salva o texto
            if (!event.shiftKey) {
                event.preventDefault(); // Evita a quebra de linha
                handleSave();
            }
        }
    };

    return (
        <div>
            <Paper
                component="form"
                sx={{
                    animation: isShaking ? `${shake} 0.3s ease` : 'none',
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    border: '1px solid #ccc',
                    borderRadius: '10px',
                    boxShadow: 'none',
                    backgroundColor: isExceeded ? 'rgba(255, 0, 0, 0.1)' : 'transparent',
                }}
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSave();
                }}
                ref={paperRef}
            >
                <InputBase
                    {...props}
                    inputRef={inputRef}
                    sx={{
                        ...(fontSize && { fontSize }),
                        ml: 1,
                        flex: 1,
                        paddingLeft: '5px',
                        color: isExceeded ? 'red' : 'inherit',
                    }}
                    placeholder={label}
                    inputProps={{ 'aria-label': 'input' }}
                    value={inputValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    multiline
                    maxRows={10}
                    onKeyDown={handleKeyDown}
                />
            </Paper>
            {!!limit && (
                <Typography
                    variant="body2"
                    color={isExceeded ? "error" : "textSecondary"}
                    sx={{ margin: '0px 0px 0px 15px' }}
                >
                    {`${inputValue.length}/${limit}`}
                </Typography>
            )}
        </div>
    );
});

export default KanbanInput;
