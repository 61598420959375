import isColorDark from "./isColorDark";

import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import NotificationsIcon from '@mui/icons-material/Notifications';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import { Box, Tooltip, Typography } from "@mui/material";
import TaskAltIcon from '@mui/icons-material/TaskAlt';

// Definição de cores para os tipos
const renderColor = {
    whatsapp: "#25d366",
    schedule: "#ffcc00",
    email: "#cd2030",
    call: "#3923ef",
    default: "#3923ef"
};

const calendarEventContent = (eventInfo) => {
    const { type = 'default', color: eventColor, isReschedule, isRescheduled, isFinished } = eventInfo.event.extendedProps || {};
    const color = eventColor || renderColor[type];

    // Define a cor do texto com base na cor de fundo
    const textColor = isColorDark(color) ? '#ffffff' : '#000000';

    return (
        <Box
            sx={{
                border: (theme) => `0px solid ${theme.palette.primary.main}`,
                backgroundColor: color,
                borderRadius: '1px',
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
                position: 'relative',
                transition: 'background-color 0.3s ease',
                '&:hover': {
                    backgroundColor: isColorDark(color) ? '#444444' : '#e0e0e0',
                },
            }}
        >
            <Typography
                sx={{
                    ml: 1,
                    color: textColor,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: 500,
                    fontSize: '0.9rem',
                    maxWidth: '100%',
                    ...(isRescheduled || isFinished ? { textDecoration: 'line-through' } : {}),
                }}
            >
                {eventInfo.event.title}
            </Typography>
            {isRescheduled && (
                <AlarmOffIcon
                    sx={{
                        color: textColor,
                        width: '18px',
                        height: '18px'
                    }}
                />
            )}
            {isFinished && (
                <TaskAltIcon
                    sx={{
                        color: textColor,
                        width: '18px',
                        height: '18px'
                    }}
                />
            )}
        </Box>
    );
};

export default calendarEventContent;
