import * as React from 'react';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Avatar, CircularProgress, Divider, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';
import api from '../../services/api';

export default function ContactAutoComplete({ placeholder, cardId }) {
    const theme = useTheme();
    const [searchParam, setSearchParam] = React.useState('');
    const [contacts, setContacts] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [selectedContact, setSelectedContact] = React.useState(null);

    const debounceTimeout = React.useRef(null);

    // Carregar o contato inicial para o card
    React.useEffect(() => {
        const fetchCardContact = async () => {
            if (!cardId) return;
            setLoading(true);
            try {
                const { data } = await api.get(`/kanban/card/requester/${cardId}`);
                if (data?.requester) {
                    setContacts([data.requester]);
                    setSelectedContact(data.requester);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        fetchCardContact();
    }, [cardId]);

    // Função para buscar contatos com debounce
    const handleSearchContact = async (value) => {
        if (!value || value.length < 3) {
            setContacts([]);
            return;
        }

        setLoading(true);
        try {
            const { data } = await api.get('/listContacts', {
                params: { searchParam: value.toLowerCase() }
            });
            setContacts(data?.contacts || []);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    // Manipulador de mudanças no input
    const handleInputChange = (event, newValue) => {
        setSearchParam(newValue);

        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            handleSearchContact(newValue);
        }, 500);
    };

    // Função para atualizar o contato selecionado
    const handleSelectContact = async (contact) => {
        if (!contact) {
            setSelectedContact(null);
            return;
        }

        setSelectedContact(contact);

        if (cardId) {
            try {
                setLoading(true);
                await api.put(`/kanban/card/${cardId}`, {
                    requesterId: contact.id
                });
            } catch (error) {
                console.error('Error updating card contact:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    // Função para limpar a seleção
    const handleClearSelection = async () => {
        if (cardId) {
            try {
                setLoading(true);
                await api.put(`/kanban/card/${cardId}`, {
                    requesterId: null
                });
                setSelectedContact(null);
                setSearchParam('');
                setContacts([]);
            } catch (error) {
                console.error('Error updating card contact:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <Autocomplete
            size="small"
            id="contact-autocomplete"
            sx={{ width: '100%' }}
            options={contacts}
            autoHighlight
            value={selectedContact}
            inputValue={searchParam}
            onInputChange={handleInputChange}
            onChange={(event, newValue) => handleSelectContact(newValue)}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            getOptionLabel={(option) => option ? `${option.name} (${option.number})` : ''}
            noOptionsText="Nada aqui, digite para pesquisar..."
            renderOption={(props, option) => (
                <Box
                    component="li"
                    key={option.id}
                    sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                    {...props}
                >
                    <Avatar
                        src={option.profilePicUrl}
                        sx={{ width: 30, height: 30, border: '1px solid white', marginRight: 1 }}
                    />
                    <Box sx={{ flexGrow: 1, fontWeight: 500, color: 'text.primary' }}>
                        {option.name}
                    </Box>
                    <Box sx={{ fontSize: '0.875rem', color: 'text.secondary' }}>
                        {option.isGroup ? 'Grupo de whatsapp' : `+${option.number}`}
                    </Box>
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    sx={{ '& .MuiOutlinedInput-root': { padding: '4px 5px 4px 6px !important' } }}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <>
                                <Typography sx={{ marginLeft: 2, fontSize: '0.75rem', fontWeight: 600, color: theme.palette.text.secondary }}>
                                    {placeholder}
                                </Typography>
                                <Divider sx={{ margin: '0px 10px' }} orientation="vertical" flexItem />
                            </>
                        ),
                        endAdornment: (
                            <>
                                {loading ? (
                                    <CircularProgress size={20} />
                                ) : (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        {selectedContact && (
                                            <IconButton onClick={handleClearSelection} sx={{ padding: 0 }}>
                                                <ClearIcon sx={{ color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000' }} />
                                            </IconButton>
                                        )}
                                        <AddIcon />
                                    </Box>
                                )}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}