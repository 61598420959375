function IdentifyGender(word) {

    const trimmedWord = word.trim().toLowerCase();

    const feminineRules = [
        { suffix: "a", exception: ["problema", "tema", "sistema"] },
        { suffix: "ção" },
        { suffix: "são" },
        { suffix: "gem" },
        { suffix: "dade" },
        { suffix: "tude" },
        { suffix: "ite" },
        { suffix: "ez" },
        { suffix: "triz" },
    ];

    const masculineRules = [
        { suffix: "o" },
        { suffix: "r" },
        { suffix: "l" },
        { suffix: "z" },
        { suffix: "u" },
        { suffix: "e", exception: ["imagem", "metade", "sede"] },
        { suffix: "ema" },
        { suffix: "oma" }
    ];

    const checkGender = (word, rules) => {
        for (const rule of rules) {
            const { suffix, exception } = rule;
            if (word.endsWith(suffix) && (!exception || !exception.includes(word))) {
                return true;
            }
        }
        return false;
    };

    if (checkGender(trimmedWord, feminineRules)) {
        return "f"; 
    }
    if (checkGender(trimmedWord, masculineRules)) {
        return "m"; 
    }

    return "n"; 
}

export default IdentifyGender;
