import toast from 'react-hot-toast';
import { i18n } from '../../translate/i18n';

const getThemeMode = () => {
	const theme = localStorage.getItem('settings');
	const props = JSON.parse(theme);
	return props?.themeMode || 'light';
};

const getErrorMessage = (err) => {
	const errorMsg = err.response?.data?.error;

	if (errorMsg) {
		const translatedError = i18n.exists(`backendErrors.${errorMsg}`)
			? i18n.t(`backendErrors.${errorMsg}`)
			: null;

		return translatedError || errorMsg;
	}
	console.error(err);
	return typeof err === 'string'
		? err
		: 'Erro inesperado, recarregue a pagina e tente novamente!';
};

const ToastError = (err) => {
	console.warn(err)
	const themeMode = getThemeMode();
	const toastOptions = {
		error: {
			style: themeMode === 'dark' ? { border: '1px solid #2d3137', color: '#fff', background: '#312d46' } : {},
		},
	};

	const errorMessage = getErrorMessage(err);

	toast.error(errorMessage, toastOptions.error);
};

export default ToastError;
