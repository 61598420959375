import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import Image from '../Image';
import ImageLightBox from '../ImageLightBox';
import {
    PictureAsPdf as PdfIcon,
    InsertDriveFile as FileIcon,
    Audiotrack as AudioIcon,
    Movie as VideoIcon,
    Code as CodeIcon,
    TextSnippet as TextIcon,
} from '@mui/icons-material';

// Mapeamento dinâmico de extensões para tipos de arquivos e seus ícones
const fileTypeMapping = {
    image: {
        types: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'],
        render: (url, name) => (
            <ImageLightBox src={url} alt={name} sx={{ height: '100px', width: '100%' }} />
        ),
    },
    video: {
        types: ['mp4', 'webm', 'ogg', 'avi', 'mov', 'mkv'],
        render: (url) => (
            <Box
                component="video"
                controls
                src={url}
                sx={{ width: '100%', height: '100px', borderRadius: 1 }}
            />
        ),
        icon: <VideoIcon sx={{ fontSize: 48, color: 'primary.main' }} />,
    },
    audio: {
        types: ['mp3', 'wav', 'ogg', 'aac', 'flac'],
        icon: <AudioIcon sx={{ fontSize: 48, color: 'secondary.main' }} />,
    },
    pdf: {
        types: ['pdf'],
        icon: <PdfIcon sx={{ fontSize: 48, color: 'error.main' }} />,
    },
    code: {
        types: ['html', 'css', 'js', 'json', 'xml', 'tsx', 'jsx', 'py', 'java', 'c', 'cpp', 'sh'],
        icon: <CodeIcon sx={{ fontSize: 48, color: 'info.main' }} />,
    },
    text: {
        types: ['txt', 'md', 'rtf', 'log'],
        icon: <TextIcon sx={{ fontSize: 48, color: 'text.primary' }} />,
    },
    other: {
        types: [],
        icon: <FileIcon sx={{ fontSize: 48, color: 'text.secondary' }} />,
    },
};

const getFileType = (url) => {
    const extension = url.split('.').pop().toLowerCase();

    for (const [type, { types }] of Object.entries(fileTypeMapping)) {
        if (types.includes(extension)) return type;
    }

    return 'other';
};

const KanbanCardCommentsMediasPreviews = ({ medias }) => {
    const renderPreview = (media) => {
        const fileType = getFileType(media.mediaUrl);
        const fileConfig = fileTypeMapping[fileType];

        if (fileConfig?.render) {
            return fileConfig.render(media.mediaUrl, media.mediaName);
        }

        // Para tipos com ícones e links externos
        return (
            <IconButton
                component="a"
                href={media.mediaUrl}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                    width: '100px',
                    height: '100px',
                    backgroundColor: 'grey.200',
                    borderRadius: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {fileConfig.icon || fileTypeMapping.other.icon}
            </IconButton>
        );
    };

    return (
        <Box
            sx={{
                display: 'grid',
                gap: 2,
                gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
                gridAutoRows: 'auto',
                justifyContent: 'center',
            }}
        >
            {medias.map((media, index) => (
                <Box
                    key={index}
                    sx={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {renderPreview(media)}
                    <Typography
                        variant="caption"
                        sx={{ mt: 1, textAlign: 'center', wordWrap: 'break-word' }}
                    >
                        {media.mediaName}
                    </Typography>
                </Box>
            ))}
        </Box>
    );
};

export default KanbanCardCommentsMediasPreviews;
