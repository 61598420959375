import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { Box, Button, ButtonGroup, Container, FormControl, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEvent } from 'react-use';

import ConfirmationModal from '../../components/ConfirmationModal';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import KanbanColumn from '../../components/KanbanColumn';
import KanbanFiltersDrawer from '../../components/KanbanFiltersDrawer';
import KanbanInput from '../../components/KanbanInput';
import KanbanSkeleton from '../../components/KanbanSkeleton';
import Page from '../../components/Page';
import socket from '../../hooks/useSocket';
import SearchIcon from '@mui/icons-material/Search';
import api from '../../services/api';
import { i18n } from '../../translate/i18n';
import { hideScrollbarX } from '../../utils/cssStyles';
import { useHistory } from "react-router-dom";

const initialLoading = { board: false, priority: false, users: false, listBoard: false };

export default function KanbanBoard() {
  const history = useHistory();
  const [kanban, setKanban] = useState(null);
  const [kanbanFilter, setKanbanFilter] = useState(null);
  const [addingColumn, setAddingColumn] = useState(false);
  const [kanbanList, setKanbanList] = useState([]);
  const { kanbanId } = useParams();
  const [loading, setLoading] = useState(initialLoading);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [typeActionModal, setTypeActionModal] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [priorities, setPriorities] = useState([]);
  const [filters, setFilters] = useState(null);
  const [searchParam, setSearchParam] = useState(null);

  const [focusedSearch, setFocusedSearch] = useState(false);

  useEffect(() => {
    if (!kanbanId) return;
    (async () => {
      try {
        setLoading((prev) => ({ ...prev, priority: true }));
        const { data } = await api.get(`/kanban/priority/${kanbanId}`);
        setPriorities(data);
      } catch (err) {
      } finally {
        setLoading((prev) => ({ ...prev, priority: false }));
      }
    })();
  }, [kanbanId]);

  const handleChangeCardPosition = async (result) => {
    const { source, destination, draggableId } = result;
    const cardId = draggableId;
    if (!destination) return;
    const oldKanbanData = kanban;
    if (source.droppableId !== destination.droppableId) {
      try {
        let removedCard;
        setKanban((prevState) => {
          const updatedColumns = prevState.column.map((col) => {
            const isSource = col.id === source.droppableId;
            if (isSource) {
              const currentCards = [...col.cards];
              const cardIndex = currentCards.findIndex((card) => card.id === cardId);
              [removedCard] = currentCards.splice(cardIndex, 1);
              const updatedCards = currentCards.map((card, index) => ({
                ...card,
                order: index,
              }));
              return {
                ...col,
                cards: updatedCards,
              };
            }
            return col;
          });
          return { ...prevState, column: updatedColumns };
        });
        setKanban((prevState) => {
          const updatedColumns = prevState.column.map((col) => {
            const isDestination = col.id === destination.droppableId;
            if (isDestination) {
              const destinationCards = [...col.cards];
              destinationCards.splice(destination.index, 0, removedCard);
              const updatedCards = destinationCards.map((card, index) => ({
                ...card,
                order: index,
              }));
              return {
                ...col,
                cards: updatedCards,
              };
            }
            return col;
          });
          return { ...prevState, column: updatedColumns };
        });
        await api.put(`/kanban/card/${cardId}`, { order: destination.index, columnId: destination.droppableId });
      } catch (error) {
        console.error(error);
        setKanban(oldKanbanData);
      }
    } else {
      try {
        setKanban((prevState) => {
          const updatedColumns = prevState.column.map((col) => {
            if (col.id === source.droppableId) {
              // Inverte a ordem dos cartões da coluna atual
              const currentCards = [...col.cards];
              const cardIndex = currentCards.findIndex((card) => card.id === cardId);
              const [removedCard] = currentCards.splice(cardIndex, 1); // Remove o cartão
              currentCards.splice(destination.index, 0, removedCard); // Insere o cartão na nova posição

              // Atualiza as ordens e reverte para a ordem original
              const updatedCards = currentCards.map((card, index) => ({
                ...card,
                order: index,
              }));

              return { ...col, cards: updatedCards };
            }
            return col; // Retorna a coluna inalterada
          });

          return {
            ...prevState,
            column: updatedColumns,
          };
        });

        await api.put(`/kanban/card/${cardId}`, { order: destination.index });
      } catch (error) {
        console.error(error);
        setKanban(oldKanbanData);
      }
    }
  };

  const handleChangeColumnPosition = async ({ source, destination, draggableId }) => {
    if (!destination || source.index === destination.index) return;
    const oldKanbanData = kanban;
    const newPosition = destination.index;
    try {
      setKanban((prevState) => {
        const columns = [...prevState.column];
        const [movedColumn] = columns.splice(source.index, 1);
        columns.splice(newPosition, 0, { ...movedColumn, position: newPosition });
        const reorderedColumns = columns.map((col, index) => ({ ...col, position: index }));
        return { ...prevState, column: reorderedColumns };
      });
      await api.put(`/kanban/column/${draggableId}`, { position: newPosition });
    } catch (error) {
      setKanban(oldKanbanData);
      console.error(error);
    }
  };

  const onDragEnd = (result) => {
    const { source, destination, type } = result;
    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index) return;
    type === 'COLUMN' ? handleChangeColumnPosition(result) : handleChangeCardPosition(result);
  };

  const createColumn = (column) => {
    setKanban((prevState) => {
      const newPosition = prevState?.column?.length || 0;
      const updatedColumns = [...prevState.column, { ...column, position: newPosition }];
      return {
        ...prevState,
        column: updatedColumns,
      };
    });
  };

  const createCard = async (columnId, card) => {
    try {
      await api.post(`/kanban/card/${columnId}`, { title: card.title, description: '' });
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const handleCreateColumn = async (inputVal) => {
    try {
      const { data } = await api.post(`/kanban/column/${kanban.id}`, { title: inputVal });
      //  createColumn({ id: data.id, title: data.title, cards: [] });
    } catch (error) {
      console.error(error);
    } finally {
      setAddingColumn(false);
    }
  };

  const handleDeleteColumn = async (columnId) => {
    try {
      await api.delete(`/kanban/column/${columnId}`, {
        params: { action: 'archive' },
      });
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const handleCleanColumn = async (columnId) => {
    try {
      await api.delete(`/kanban/column/${columnId}`, {
        params: { action: 'clean' },
      });
    } catch (error) {
      console.error(error);
    } finally {
      // Ações no final, se necessário
    }
  };

  const renameColumn = async (column) => {
    const oldKanbanData = { ...kanban };
    try {
      setKanban((prevState) => ({
        ...prevState,
        column: prevState.column.map((col) => (col.id === column.id ? { ...col, title: column.title } : col)),
      }));
      await api.put(`/kanban/column/${column.id}`, { title: column.title });
    } catch (error) {
      setKanban(oldKanbanData);
      console.error('Erro ao renomear a coluna:', error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading((prev) => ({ ...prev, board: true }));
        const { data } = await api.get(`/kanban/board/${kanbanId}`);
        setKanban(data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading((prev) => ({ ...prev, board: false }));
      }
    })();
  }, [kanbanId]);


  useEffect(() => {
    (async () => {
      try {
        setLoading((prev) => ({ ...prev, listBoard: true }));
        const { data } = await api.get(`/kanban/list`);
        console.info(data);
        setKanbanList(data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading((prev) => ({ ...prev, listBoard: false }));
      }
    })();
  }, []);

  useEffect(() => {
    socket.on('kanban-card', (data) => {
      if (data?.kanbanId === kanbanId) {
        if (data.action === 'create') {
          setKanban((prevState) => {
            const updatedColumns = prevState.column.map((col) => {
              if (col.id === data.columnId) {
                const newCards = [data.card, ...col.cards];
                const updatedCards = newCards.map((card, index) => ({
                  ...card,
                  order: index,
                }));

                return { ...col, cards: updatedCards };
              }
              return col;
            });
            return {
              ...prevState,
              column: updatedColumns,
            };
          });
        }

        if (data.action === 'update') {
          setKanban((prevData) => {
            const updatedColumns = prevData.column.map((c) => {
              const cardIndex = c.cards.findIndex((card) => card.id === data.cardId);
              if (cardIndex !== -1) {
                const updatedCards = [...c.cards];
                updatedCards[cardIndex] = { ...updatedCards[cardIndex], ...data.card };
                return { ...c, cards: updatedCards };
              }
              return c;
            });
            return { ...prevData, column: updatedColumns };
          });
        }

        if (data.action === 'order') {
          setKanban((prevState) => {
            const updatedColumns = prevState.column.map((c) => {
              if (c.id === data.columnId) {
                const currentCards = [...c.cards];
                const cardIndex = currentCards.findIndex((card) => card.id === data.cardId);
                const removedCard = currentCards.splice(cardIndex, 1);
                const newPosition = data.card.order;
                currentCards.splice(newPosition, 0, removedCard[0]);
                const updatedCards = currentCards.map((card, index) => ({
                  ...card,
                  order: index,
                }));
                return { ...c, cards: updatedCards };
              }
              return c;
            });
            return {
              ...prevState,
              column: updatedColumns,
            };
          });
        }

        if (data.action === 'column') {
          setKanban((prevState) => {
            const updatedColumns = prevState.column.map((col) => {
              const isSource = col.id === data.oldColumnId;
              if (isSource) {
                const currentCards = [...col.cards];
                const cardIndex = currentCards.findIndex((card) => card.id === data.cardId);

                if (cardIndex > -1) {
                  currentCards.splice(cardIndex, 1);
                  const updatedCards = currentCards.map((card, index) => ({
                    ...card,
                    order: index,
                  }));

                  return {
                    ...col,
                    cards: updatedCards,
                  };
                }
              }
              return col;
            });
            return { ...prevState, column: updatedColumns };
          });

          setKanban((prevState) => {
            const updatedColumns = prevState.column.map((col) => {
              const isDestination = col.id === data.columnId;
              if (isDestination) {
                const cardExists = col.cards.some((card) => card.id === data.card.id);
                if (cardExists) {
                  return col;
                }
                const destinationCards = [...col.cards];
                const newPosition = data.card.order;
                destinationCards.splice(newPosition, 0, data.card);
                const updatedCards = destinationCards.map((card, index) => ({
                  ...card,
                  order: index,
                }));
                return {
                  ...col,
                  cards: updatedCards,
                };
              }
              return col;
            });
            return { ...prevState, column: updatedColumns };
          });
        }

        if (data.action === 'delete') {
          setKanban((prevData) => {
            const updatedColumns = prevData.column.map((column) => {
              const updatedCards = column.cards.filter((card) => card.id !== data.cardId);
              return { ...column, cards: updatedCards };
            });
            return { ...prevData, column: updatedColumns };
          });
        }
      }
    });

    socket.on('kanban-assigneds', (data) => {
      setKanban((prevData) => {
        const updatedColumns = prevData.column.map((column) => {
          const cardIndex = column.cards.findIndex((card) => card.id === data.cardId);
          if (cardIndex !== -1) {
            const updatedCards = [...column.cards];
            updatedCards[cardIndex] = { ...updatedCards[cardIndex], assigned: data.assigneds };
            return { ...column, cards: updatedCards };
          }
          return column;
        });
        return { ...prevData, column: updatedColumns };
      });
    });

    socket.on('kanban-comments', (data) => {
      setKanban((prevData) => {
        const updatedColumns = prevData.column.map((column) => {
          const cardIndex = column.cards.findIndex((card) => card.id === data.cardId);
          if (cardIndex !== -1) {
            const updatedCards = [...column.cards];
            updatedCards[cardIndex] = { ...updatedCards[cardIndex], comments: data.comments };
            return { ...column, cards: updatedCards };
          }
          return column;
        });
        return { ...prevData, column: updatedColumns };
      });
    });

    socket.on('kanban-task', (data) => {
      setKanban((prevData) => {
        const updatedColumns = prevData.column.map((column) => {
          const cardIndex = column.cards.findIndex((card) => card.id === data.cardId);
          if (cardIndex !== -1) {
            const updatedCards = [...column.cards];
            updatedCards[cardIndex] = { ...updatedCards[cardIndex], tasks: data.tasks };
            return { ...column, cards: updatedCards };
          }
          return column;
        });
        return { ...prevData, column: updatedColumns };
      });
    });

    socket.on('kanban-task-checklist', (data) => {
      setKanban((prevData) => {
        const updatedColumns = prevData.column.map((column) => {
          const cardIndex = column.cards.findIndex((card) => card.id === data.cardId);
          if (cardIndex !== -1) {
            const updatedCards = [...column.cards];
            const tasks = updatedCards[cardIndex].tasks || [];
            const taskIndex = tasks.findIndex((task) => task.id === data.taskId);
            if (taskIndex !== -1) {
              const updatedTasks = [...tasks];
              updatedTasks[taskIndex] = {
                ...updatedTasks[taskIndex],
                checklists: data.checklists,
              };
              updatedCards[cardIndex] = {
                ...updatedCards[cardIndex],
                tasks: updatedTasks,
              };
              return { ...column, cards: updatedCards };
            }
          }
          return column;
        });
        return { ...prevData, column: updatedColumns };
      });
    });

    socket.on('kanban-column', (data) => {
      if (data?.kanbanId === kanbanId) {
        if (data.action === 'position') {
          const newPosition = data.column.position;
          setKanban((prevState) => {
            const columns = [...prevState.column];
            const columnIndex = columns.findIndex((col) => col.id === data.columnId);
            const [movedColumn] = columns.splice(columnIndex, 1);
            columns.splice(newPosition, 0, { ...movedColumn, position: newPosition });
            const reorderedColumns = columns.map((col, index) => ({ ...col, position: index }));
            return { ...prevState, column: reorderedColumns };
          });
        }
        if (data.action === 'update') {
          setKanban((prevState) => ({
            ...prevState,
            column: prevState.column.map((col) =>
              col.id === data.columnId ? { ...col, title: data.column.title } : col
            ),
          }));
        }
        if (data.action === 'create') {
          setKanban((prevState) => {
            const columnExists = prevState.column.some((col) => col.id === data.column.id);
            let updatedColumns = [...prevState.column];
            if (columnExists) {
              updatedColumns = updatedColumns.filter((col) => col.id !== data.column.id);
            }
            const newPosition = updatedColumns.length;
            updatedColumns.push({ ...data.column, position: newPosition });
            return { ...prevState, column: updatedColumns };
          });
        }
        if (data.action === 'delete') {
          setKanban((prevState) => {
            const columnExists = prevState.column.some((col) => col.id === data.columnId);
            if (columnExists) {
              const updatedColumns = prevState.column.filter((col) => col.id !== data.columnId);
              return { ...prevState, column: updatedColumns };
            }
            return prevState;
          });
        }
      }
    });

    socket.on(`kanban-priority`, (data) => {
      if (data?.priority?.kanbanId === kanbanId) {
        if (data.action === 'create') {
          setPriorities((pre) => [...pre, data.priority]);
        }
        if (data.action === 'update') {
          setPriorities((pre) => pre.map((priority) => (priority.id === data.priority.id ? data.priority : priority)));
        }
      }
    });

    return () => {
      socket.off('kanban-card');
      socket.off('kanban-assigneds');
      socket.off('kanban-comments');
      socket.off('kanban-task');
      socket.off('kanban-task-checklist');
      socket.off('kanban-column');
    };
  }, []);

  useEffect(() => {
    if (!open) return;
    const savedFilters = localStorage.getItem('kanbanFilters');
    if (savedFilters) {
      try {
        const parsedFilters = JSON.parse(savedFilters);
        setFilters(parsedFilters);
      } catch (error) {
        console.error('Erro ao fazer parse dos filtros salvos:', error);
      }
    }
  }, []);

  const handleFilterCards = (newFilters) => {
    setFilters(newFilters);
  };

  const handleCleanFilters = () => {
    setFilters(null);
    localStorage.removeItem('kanbanFilters');
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  function filterCardsInColumns(data, searchValue) {
    if (!searchValue) return data;

    const lowerCaseSearchValue = searchValue.toLowerCase();

    return {
      ...data,
      column: data.column.map((column) => ({
        ...column,
        cards: column.cards.filter(
          (card) =>
            (card.title && card.title.toLowerCase().includes(lowerCaseSearchValue)) ||
            (card.taskNumber && card.taskNumber.toLowerCase().includes(lowerCaseSearchValue))
        ),
      })),
    };
  }

  useEffect(() => {
    const kanbanFiltered = filterCardsInColumns(kanban, searchParam);
    setKanbanFilter(kanbanFiltered);
  }, [searchParam]);

  useEffect(() => {
    setKanbanFilter(kanban);
  }, [kanban]);

  const columns = kanbanFilter?.column?.sort((a, b) => a.position - b.position) || null;

  const handleChangeBoard = (e) => {
    if (e.target.value) {
      history.push(`/kanban/board/${e.target.value}`)
    }
  };

  return (
    <>
      <Box
        component="div"
        sx={{
          marginTop: '5px',
          // background: 'url(https://picsum.photos/1920/1080)',
          backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#161c24' : '#ffffff',
          height: '100%'
        }}>
        <Page title={i18n.t('mainDrawer.listItems.kanban')}>
          <Container maxWidth={false}>
            <HeaderBreadcrumbs
              heading={
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="kanban-board-select-label">Quadro</InputLabel>
                  <Select
                    disabled={loading?.board}
                    labelId="kanban-board-select-label"
                    id="kanban-board-select"
                    inputProps={{ 'aria-label': 'Without label' }}
                    value={loading?.board ? "" : kanbanId || ""}
                    onChange={e => handleChangeBoard(e)}
                    label="Quadro"
                    sx={{
                      '& .MuiSelect-select': {
                        padding: '8px 32px 8px 14px',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(0, 0, 0, 0.87)',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: (theme) => theme.palette.primary.main,
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em>Todos</em>
                    </MenuItem>
                    {(kanbanList || []).map((item) => <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>)}
                  </Select>
                </FormControl>
              }
              action={
                <Box
                  sx={{
                    display: 'flex',
                    marginTop: '10px',
                  }}
                >
                  <TextField
                    fullWidth
                    sx={{
                      width: focusedSearch ? '500px' : '300px',
                      minWidth: '300px',
                      marginRight: '20px',
                      transition: 'width 0.2s ease',
                      '& .MuiInputBase-input': {
                        padding: '3.5px 5px',
                      },
                    }}
                    size="small"
                    type="search"
                    placeholder={`Buscar Titulo ou N° ${kanban?.taskTitle
                      ? kanban.taskTitle.charAt(0).toUpperCase() + kanban.taskTitle.slice(1)
                      : 'Protocolo'
                      }`}
                    value={searchParam}
                    onChange={handleSearch}
                    onFocus={() => setFocusedSearch(true)}
                    onBlur={() => setFocusedSearch(false)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{ color: 'gray' }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {filters ? (
                    <ButtonGroup aria-label="Basic button group">
                      <Button
                        variant="outlined"
                        startIcon={<Iconify icon="solar:filter-bold" width={20} />}
                        onClick={() => setOpenFilters(true)}
                        size="small"
                      >
                        Filtros
                      </Button>
                      <Button
                        variant="contained"
                        endIcon={<Iconify icon="ic:round-clear" width={20} />}
                        onClick={() => handleCleanFilters()}
                        size="small"
                      >
                        Remover
                      </Button>
                    </ButtonGroup>
                  ) : (
                    <Button
                      size="small"
                      startIcon={<Iconify icon="solar:filter-bold" width={20} />}
                      variant="outlined"
                      onClick={() => setOpenFilters(true)}
                    >
                      Filtros
                    </Button>
                  )}
                </Box>
              }
            />
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="all-columns" direction="horizontal" type="COLUMN">
                {(provided) => (
                  <Stack
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    spacing={2}
                    direction="row"
                    alignItems="flex-start"
                    sx={{
                      height: 1,
                      overflowY: 'auto',
                      ...hideScrollbarX,
                    }}
                  >
                    {loading.board ? (
                      <KanbanSkeleton />
                    ) : columns?.length > 0 ? (
                      columns.map((column, index) => {
                        return (
                          <KanbanColumn
                            kanban={kanban}
                            key={column.id}
                            index={index}
                            filters={filters}
                            taskTitle={kanban?.taskTitle || 'protocolo'}
                            column={column}
                            assigneds={kanban?.assigneds}
                            priorities={priorities}
                            cards={column?.cards || []}
                            createCard={createCard}
                            clearColumn={() => {
                              setTypeActionModal({ action: 'clean', columnId: column.id, title: column.title });
                              setOpenConfirmationModal(true);
                            }}
                            deleteColumn={() => {
                              setTypeActionModal({ action: 'archive', columnId: column.id, title: column.title });
                              setOpenConfirmationModal(true);
                            }}
                            renameColumn={renameColumn}
                          />
                        );
                      })
                    ) : null}
                    {provided.placeholder}
                    <Box sx={{ ml: 2, mt: 1, minWidth: 280 }}>
                      {addingColumn ? (
                        <KanbanInput
                          label="Titulo"
                          limit={25}
                          onSave={handleCreateColumn}
                          onCancel={() => {
                            console.info('outsider');
                            setAddingColumn(false);
                          }}
                        />
                      ) : (
                        <Button
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            setAddingColumn(true);
                          }}
                          variant="outlined"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '0.875rem',
                            fontWeight: 500,
                            width: '100%',
                            border: '1px dashed',
                            borderColor: (theme) => theme.palette.primary.main,
                            color: (theme) => theme.palette.primary.main,
                            '&:hover': {
                              color: '#fff',
                              borderColor: (theme) => theme.palette.primary.main,
                              backgroundColor: (theme) => theme.palette.primary.main,
                            },
                          }}
                        >
                          <Iconify icon="carbon:add" width={20} />
                          <Box component="span" sx={{ ml: 1 }}>
                            Add Column
                          </Box>
                        </Button>
                      )}
                    </Box>
                  </Stack>
                )}
              </Droppable>
            </DragDropContext>
          </Container>
        </Page>
        <KanbanFiltersDrawer
          taskTitle={kanban?.taskTitle || 'protocolo'}
          priorities={priorities}
          loadingPriority={loading.priority}
          open={openFilters}
          onClose={setOpenFilters}
          kanban={kanban}
          onFilter={handleFilterCards}
        />
        <ConfirmationModal
          title={
            typeActionModal?.action === 'clean'
              ? `Limpar a coluna "${typeActionModal?.title}"?`
              : `Arquivar a coluna "${typeActionModal?.title}"?`
          }
          open={openConfirmationModal}
          onClose={() => {
            setOpenConfirmationModal(false);
            setTypeActionModal(null);
          }}
          onConfirm={async () =>
            typeActionModal?.action === 'clean'
              ? await handleCleanColumn(typeActionModal?.columnId)
              : await handleDeleteColumn(typeActionModal?.columnId)
          }
        >
          {typeActionModal?.action === 'clean'
            ? 'Você tem certeza de que deseja limpar esta coluna? Esta ação não pode ser desfeita, e todas as tarefas vinculadas serão permanentemente removidas.'
            : 'Você tem certeza de que deseja archivar esta coluna?  todas as tarefas vinculadas serão permanentemente na coluna que foi arquivada.'}
        </ConfirmationModal>
      </Box>
    </>
  );
}
