import React, { useState } from 'react';
import { IconButton, Popover, Button, Typography, Box } from '@mui/material';
import { Delete } from '@mui/icons-material';

const DeleteTaskButton = ({ taskId, handleDeleteItem }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleConfirmDelete = () => {
        handleDeleteItem(taskId);
        handleClose();
    };

    return (
        <>
            <IconButton size="small" onClick={handleClick}>
                <Delete fontSize="small" />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    sx: {
                        width: 360,
                        padding: 3,
                        borderRadius: 2,
                        boxShadow: 3,
                    },
                }}
            >
                <Typography variant="h6" sx={{ textAlign: 'center', mb: 2, fontWeight: 'bold' }}>
                    <strong>Atenção!</strong>
                </Typography>
                <Typography sx={{ textAlign: 'center', mb: 3 }}>
                    Você está prestes a excluir este item. Esta ação <strong>não pode ser desfeita</strong>.
                    <br />
                    Tem certeza de que deseja continuar?
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <Button
                        onClick={handleClose}
                        variant="outlined"
                        size="small"
                        sx={{ width: '45%' }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleConfirmDelete}
                        variant="contained"
                        color="error"
                        size="small"
                        sx={{ width: '45%' }}
                    >
                        Confirmar
                    </Button>
                </Box>
            </Popover>
        </>
    );
};

export default DeleteTaskButton;
