import React, { useEffect, useState } from 'react';
import {
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Tabs,
  Tab,
  Box,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Chip,
  Stack,
} from '@mui/material';
import ToastError from '../../toast/error/toastError';
import api from '../../services/api';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const KanbanColumnSettingsModal = ({ kanban, columnId, open, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [column, setColumn] = useState(null);
  const [users, setUsers] = useState([]);
  const [connections, setConnections] = useState([]);
  const [selectedConnections, setSelectedConnections] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const filterOptions = createFilterOptions({ trim: true });

  const handleClose = () => {
    setColumn(null);
    setUsers([]);
    setConnections([]);
    setSelectedConnections([]);
    setActiveTab(0);
    setSelectedUsers([]);
    setTimeout(() => {
      onClose(false);
    }, 10);
  };

  useEffect(() => {
    if (open && columnId) {
      (async () => {
        try {
          setLoading(true);

          // Fazendo as requisições em paralelo
          const [columnResponse, usersResponse, connectionsResponse] = await Promise.all([
            api.get(`/kanban/column/${columnId}`),
            api.get('/users/', { params: { isActive: true, getAllUsers: true } }),
            api.get('/whatsapp/'),
          ]);

          const columnData = columnResponse.data;
          const activeUsers = usersResponse.data?.users || [];
          const connections = connectionsResponse.data || [];

          // Atualizando estados com os dados
          setConnections(connections);
          setColumn(columnData);
          setUsers(activeUsers);

          // Filtrando usuários, se necessário
          if (columnData?.createTicketUser) {
            const selectedUser = activeUsers.find((user) => user.id === columnData.createTicketUser);
            setSelectedUsers(selectedUser ? [selectedUser] : []);
          }

          if (columnData?.connectionId) {
            const selectedConnections = connections.find((connection) => connection.id === columnData.connectionId);
            setSelectedConnections(selectedConnections ? [selectedConnections] : []);
          }
        } catch (error) {
          ToastError(error); // Mostrando mensagem de erro
        } finally {
          setLoading(false); // Garantindo que o loading será desativado
        }
      })();
    }
  }, [open, columnId]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleNewListOfAssigned = async (assignedUsers) => {
    const oldAssigneds = selectedUsers;
    try {
      setSelectedUsers(assignedUsers);
    } catch (err) {
      setSelectedUsers(oldAssigneds);
    }
  };

  const handleNewListOfAssignedConnections = async (assignedConnections) => {
    const oldAssigneds = selectedConnections;
    try {
      setSelectedConnections(assignedConnections);
    } catch (err) {
      setSelectedConnections(oldAssigneds);
    }
  };

  const handleUpdateColumnSettings = async () => {
    try {
      setLoading(true);
      await api.put(`/kanban/column/${columnId}`, {
        hasStart: column?.hasStart,
        hasFinished: column?.hasFinished,
        sendMessage: column?.sendMessage,
        message: column?.message,
        createTicket: column?.createTicket,
        createTicketIn: column?.createTicketIn,
        createTicketUser: selectedUsers?.id,
        connectionId: selectedConnections?.id,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClickAddText = (text) => {
    setColumn({ ...column, message: column?.message + ' ' + text });
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="confirm-dialog"
      fullWidth
      maxWidth="sm"
      sx={{
        '& .MuiDialog-container .MuiPaper-root': {
          borderRadius: '12px',
          width: '100%',
          padding: '16px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
        },
      }}
      BackdropProps={{
        style: {
          backdropFilter: 'blur(5px)',
          backgroundColor: 'rgba(0, 0, 30, 0.5)',
        },
      }}
    >
      <DialogTitle id="confirm-dialog" sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
        {`Editar Configurações: Coluna ${column?.title}`}
      </DialogTitle>
      <DialogContent dividers>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          centered
          sx={{
            marginBottom: 2,
            '.MuiTabs-flexContainer': {
              justifyContent: 'center',
            },
          }}
        >
          <Tab label="Ações" sx={{ fontSize: '1rem', textTransform: 'none' }} />
          {column?.sendMessage && <Tab label={'Mensagem'} sx={{ fontSize: '1rem', textTransform: 'none' }} />}
        </Tabs>
        <Box mt={2}>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height={150}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {activeTab === 0 && (
                <Box>
                  <Typography variant="h6" sx={{ marginBottom: 2, fontWeight: 'bold' }}>
                    Ações ao mover cards para esta coluna:
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={column?.hasStart || false}
                            onChange={(e) => setColumn({ ...column, hasStart: e.target.checked })}
                          />
                        }
                        label="Criar data de início para o card movido"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={column?.hasFinished || false}
                            onChange={(e) => setColumn({ ...column, hasFinished: e.target.checked })}
                          />
                        }
                        label="Criar data de conclusão para o card movido"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={column?.sendMessage || false}
                            onChange={(e) => setColumn({ ...column, sendMessage: e.target.checked })}
                          />
                        }
                        label="Enviar mensagem ao solicitante (caso exista)"
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
              {activeTab === 1 && (
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      marginBottom: 2,
                      fontWeight: 'bold',
                    }}
                  >
                    Configurações de Mensagem de Ação
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          value={selectedConnections?.length > 0 ? selectedConnections[0] : null}
                          size="small"
                          getOptionLabel={(option) => `${option.name}`}
                          onChange={(e, newValue) => handleNewListOfAssignedConnections(newValue)}
                          options={connections}
                          filterOptions={filterOptions}
                          freeSolo
                          autoHighlight
                          noOptionsText="Nenhuma opção disponivel."
                          loading={loading}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              label="Pesquisar Conexão"
                              variant="outlined"
                              required
                              autoFocus
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        multiline
                        size="small"
                        maxRows={10}
                        label="Mensagem a ser enviada"
                        placeholder="Digite aqui a mensagem que será enviada ao realizar a ação..."
                        value={column?.message || ''}
                        onChange={(e) => setColumn({ ...column, message: e.target.value })}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={1}>
                        <Chip
                          size="small"
                          clickable
                          label={`N° ${
                            kanban?.taskTitle
                              ? kanban.taskTitle.charAt(0).toUpperCase() + kanban.taskTitle.slice(1)
                              : 'Protocolo'
                          }`}
                          onClick={() => handleClickAddText('{{card_protocol}}')}
                        />
                        <Chip
                          size="small"
                          clickable
                          label="Nome do contato"
                          onClick={() => handleClickAddText('{{contact_name}}')}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={column?.createTicket || false}
                            onChange={(e) => setColumn({ ...column, createTicket: e.target.checked })}
                          />
                        }
                        label="Criar atendimento automaticamente ao enviar mensagem"
                      />
                    </Grid>
                    {column?.createTicket && (
                      <Grid item xs={12}>
                        <FormControl fullWidth size="small">
                          <InputLabel id="ticket-status-label">Manter atendimento em:</InputLabel>
                          <Select
                            labelId="ticket-status-label"
                            label="Manter atendimento em:"
                            value={column?.createTicketIn || 'pending'}
                            onChange={(e) => setColumn({ ...column, createTicketIn: e.target.value })}
                          >
                            <MenuItem value="open">Atendendo</MenuItem>
                            <MenuItem value="pending">Pendentes</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                    {column?.createTicketIn === 'open' && (
                      <Grid item xs={12}>
                        <FormControl fullWidth size="small">
                          <Autocomplete
                            value={selectedUsers?.length > 0 ? selectedUsers[0] : null}
                            size="small"
                            getOptionLabel={(option) => `${option.name}`}
                            onChange={(e, newValue) => handleNewListOfAssigned(newValue)}
                            options={users}
                            filterOptions={filterOptions}
                            freeSolo
                            autoHighlight
                            noOptionsText="Nenhuma opção disponivel."
                            loading={loading}
                            renderInput={(params) => (
                              <TextField
                                size="small"
                                {...params}
                                label="Pesquisar usuário"
                                variant="outlined"
                                required
                                autoFocus
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              )}
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: '16px' }}>
        <Button variant="outlined" onClick={() => handleClose()} color="error" sx={{ textTransform: 'none' }}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          onClick={() => handleUpdateColumnSettings()}
          color="primary"
          sx={{ textTransform: 'none', boxShadow: 'none' }}
        >
          Salvar Alterações
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default KanbanColumnSettingsModal;
