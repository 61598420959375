import { Avatar, Box, Chip, CircularProgress, Divider, Typography, IconButton, List, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import api from '../../services/api';
import KanbanInput from '../KanbanInput';
import KanbanPriorityAutoComplete from '../KanbanPriorityAutoComplete';
import KanbanPriorityModal from '../KanbanPriorityModal';
import KanbanUsersAutocomplete from '../KanbanUsersAutoComplete';
import socket from "../../hooks/useSocket";
import KanbanContactsAutoComplete from '../KanbanContactsAutoComplete';
import CustomListItem from '../CustomListItem';
import EmojiPicker from '../EmojiPicker';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DropZoneIconUpload from '../DropZoneIconUpload';
import SendIcon from '@mui/icons-material/Send';
import ToastError from '../../toast/error/toastError';
import ContactAutoComplete from '../ContactAutoComplete';

const KanbanCardDetailsAndCommentsSection = ({ card, priorities, assigneds }) => {
  console.info({ card })
  const [priorityValue, setPriorityValue] = useState(card?.kanbanPriorityLevel ? [card.kanbanPriorityLevel] : []);
  const { title, creator, attachments, assigned } = card;
  const [selectedsUsers, setSelectedsUsers] = useState([]);
  const [users, setUsers] = useState(assigneds);

  // Ações para renomear titulos
  const [renamingTitle, setRenamingTitle] = useState(false);
  const [titleCard, setTitleCard] = useState(title);
  const titleRef = useRef(null); // Referência para o elemento do título

  // Ações para renomear descrição
  const [renamingDescription, setRenamingDescription] = useState(false);
  const [descriptionCard, setDescriptionCard] = useState(card?.description || '');
  const descriptionRef = useRef(null); // Referência para o elemento da descrição

  const [openPriorityModal, setOpenPriorityModal] = useState(false);

  const [loading, setLoading] = useState({
    title: false,
    description: false,
    priority: false,
    contacts: false,
    tags: false,
    users: false,
    comment: false
  });

  const [commentText, setCommentText] = useState('');
  const commentTextRef = useRef('');
  const textFieldRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [commentsArray, setCommentsArray] = useState([]);
  const listRef = useRef(null);
  const dropzoneRef = useRef(null);

  const updatePriorityValue = (values, setFunction) => {
    if (!values || values.length === 0 || (values.length === 1 && values[0] === undefined)) {
      setFunction([]);
    } else {
      setFunction([values[values.length - 1]]);
    }
  };


  useEffect(() => {
    if (card?.kanbanPriorityLevel) {
      setPriorityValue([card.kanbanPriorityLevel]);
    }
  }, [card]);

  useEffect(() => {
    if (priorityValue?.length > 0 && card?.kanbanPriorityId === priorityValue[0].id) return;
    if (priorityValue?.length === 0 && !card?.kanbanPriorityId) return;
    if (loading.priority) return;
    (async () => {
      try {
        setLoading((prev) => ({ ...prev, priority: true }));
        await api.put(`/kanban/card/${card.id}`, { kanbanPriorityId: priorityValue[0]?.id || null })
      } catch (err) {
        console.error(err)
      } finally {
        setLoading((prev) => ({ ...prev, priority: false }));
      }
    })()
  }, [priorityValue]);

  useEffect(() => {
    setSelectedsUsers(assigned);
  }, [assigned]);

  const handleNewListOfAssigned = async (assignedsUsers) => {
    const oldAssigneds = selectedsUsers;
    setLoading((prev) => ({ ...prev, users: true }));
    try {
      setSelectedsUsers(assignedsUsers); // Atualiza os usuários selecionados
      const assignedIds = Array.isArray(assignedsUsers) && assignedsUsers.length > 0
        ? assignedsUsers.map(a => a.id) // Mapeia os IDs dos usuários
        : null;
      await api.put(`/kanban/assigned/${card.id}`, { assigned: assignedIds });
    } catch (err) {
      console.error(err);
      setSelectedsUsers(oldAssigneds); // Restaura os usuários selecionados em caso de erro
    } finally {
      setLoading((prev) => ({ ...prev, users: false }));
    }
  };

  const handleSaveRenameTitle = async (value) => {
    setTitleCard(value);
    setRenamingTitle(false);
    setLoading((prev) => ({ ...prev, title: true }));
    try {
      const { data } = await api.put(`/kanban/card/${card.id}`, { title: value });
      setTitleCard(data.title);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading((prev) => ({ ...prev, title: false }));
    }
    // Você pode fazer uma chamada API aqui para salvar o novo título se necessário
  };

  const handleSaveRenameDescription = async (value) => {
    setDescriptionCard(value);
    setRenamingDescription(false);
    setLoading((prev) => ({ ...prev, description: true }));
    try {
      const { data } = await api.put(`/kanban/card/${card.id}`, { description: value });
      setDescriptionCard(data.description);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading((prev) => ({ ...prev, description: false }));
    }
    // Você pode fazer uma chamada API aqui para salvar o novo título se necessário
  };




  useEffect(() => {
    if (card.id) {
      (async () => {
        try {
          setLoading((prev) => ({ ...prev, comment: true }));
          const { data } = await api.get(`/kanban/comment/${card.id}`);
          if (data?.length > 0) {
            setCommentsArray(data.reverse())
          }
        } catch (error) {
          console.error(error)
        } finally {
          setLoading((prev) => ({ ...prev, comment: false }));
        }
      })();

    }

  }, [card])

  const handleCreatePriority = () => {
    setOpenPriorityModal(true)
  };

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [commentsArray]);

  useEffect(() => {
    // Define o foco no TextField ao carregar o componente
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, []);

  const handleSendMessage = async () => {

    try {
      setLoading((prev) => ({ ...prev, comment: true }));

      // Configura o FormData com o texto do comentário
      const formData = new FormData();
      const textMessage = commentText.trim() || commentTextRef.current.trim();

      if (textMessage?.length === 0 && files?.length === 0) {
        ToastError("Você deve selecionar uma midia ou um texto para criar um comentario.")
        return;
      };

      if (textMessage?.length > 0) {
        formData.append("text", textMessage);
      };

      // Adiciona os arquivos, se houver
      files?.forEach((file) => formData.append("medias", file));

      // Envia o comentário para a API
      const { data } = await api.post(`/kanban/comment/${card.id}`, formData);

      // Atualiza o estado com o novo comentário
      setCommentsArray((prev) => [...prev, data]);

      // Limpa o campo de comentário e refoca no TextField
      setCommentText('');
      setFiles([]);
      setTimeout(() => {
        commentTextRef.current = '';
        textFieldRef.current?.focus();
      }, 100);
    } catch (error) {
      console.error("Erro ao enviar comentário:", error);
    } finally {
      setLoading((prev) => ({ ...prev, comment: false }));
    }
  };


  const handleInputChange = (e) => {
    commentTextRef.current = e.target.value;
    setCommentText(e.target.value); // Atualiza o valor visível no campo
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };


  const handleDrop = useCallback(
    (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFiles([...files, ...newFiles]);
    },
    [files]
  );

  const handleRemoveFile = (inputFile) => {
    const filtered = files.filter((file) => file !== inputFile);
    setFiles(filtered);
  };

  const handleUploadTrigger = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.triggerUpload(); // Chama a função triggerUpload
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '24px',
          bgcolor: (theme) => theme.palette.mode === 'dark' ? '#1b212c' : '#ffffff',
          borderRadius: '8px',
          overflow: 'auto',
          height: 'calc(100vh - 130px)',  // Ajusta a altura para que o primeiro Box ocupe o restante da tela
          paddingBottom: '80px',  // Ajuste para acomodar o footer
        }}
      >
        <Box
          ref={titleRef}
          sx={{
            maxWidth: '100%',
            ...(renamingTitle ? {
              width: '100%',
            } : {
              cursor: 'pointer',
              transition: 'border 0.3s ease',
              padding: '6px 6px 6px 6px',
              ...(!loading?.title && {
                '&:hover': {
                  border: (theme) => `1px solid ${theme.palette.primary.main}`,
                  borderRadius: '10px',
                }
              })
            }),
          }}
          onClick={(e) => {
            if (loading?.title) return;
            e.stopPropagation();
            if (renamingTitle) return;
            setRenamingTitle(true);
          }}
        >
          {renamingTitle ? (
            <KanbanInput label="Titulo" limit={1000} fontSize={'1.3rem'} value={titleCard} onSave={handleSaveRenameTitle} onCancel={() => setRenamingTitle(false)} />
          ) : (
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <Typography
                sx={{
                  margin: '0px',
                  color: (theme) => theme.palette.mode === 'dark' ?
                    (loading?.title ? '#2f3641' : '#ffffff') :
                    (loading?.title ? '#b2b2b2' : '#000000')
                }}
                variant="h5"
                fontWeight="bold"
                whiteSpace="pre-wrap"
                gutterBottom
              >
                {titleCard}
              </Typography>
              {loading?.title && (
                <Box>
                  <CircularProgress size={24} color="primary" />
                </Box>
              )}
            </Box>
          )}
        </Box>
        <Box mt={2}>
          <Label>Autoria</Label>
          <StyledChip
            avatar={<Avatar alt={creator.name} src={creator.media} />}
            label={creator.name}
          />
        </Box>
        <Box mt={2}>

          {/*
          <KanbanContactsAutoComplete
            labels={contacts}
            placeholder="Solicitante"
            search={"Busque o contato solicitante"}
            setValue={(values) => updatePriorityValue(values, setSelectedsContacts)}
            value={selectedsContacts}
            loading={loading.contacts}
          />
         */}

          <ContactAutoComplete
            placeholder="Solicitante"
            cardId={card.id}
          />
        </Box>
        <Divider sx={{ margin: '30px 0px 15px 0px' }} />
        <Box mt={2}>
          <KanbanPriorityAutoComplete
            labels={priorities}
            placeholder="Prioridade"
            search={"Prioridade do Card"}
            setValue={(values) => updatePriorityValue(values, setPriorityValue)}
            value={priorityValue}
            loading={loading.priority}
            handleCreate={handleCreatePriority}
          />
        </Box>
        <Box mt={2}>
          <KanbanUsersAutocomplete
            labels={users}
            placeholder="Usuários Atribuidos"
            search={"Busque para atribuir"}
            setValue={(values) => handleNewListOfAssigned(values)}
            value={selectedsUsers}
            loading={loading.users}
          />
        </Box>
        <Box
          mt={2}
          ref={descriptionRef}
          sx={{
            maxWidth: '100%',
            ...(renamingDescription ? {
              width: '100%',
            } : {
              cursor: 'pointer',
              transition: 'border 0.3s ease',
              padding: '6px 6px 6px 6px',
              ...(!loading?.description && {
                '&:hover': {
                  border: (theme) => `1px solid ${theme.palette.primary.main}`,
                  borderRadius: '10px',
                }
              })
            }),
          }}
          onClick={(e) => {
            if (loading?.description) return;
            e.stopPropagation();
            if (renamingDescription) return;
            setRenamingDescription(true);
          }}
        >
          <Label>Descrição</Label>
          {renamingDescription ? (
            <KanbanInput label="Descrição" limit={3000} fontSize={'1rem'} multiline value={descriptionCard} onSave={handleSaveRenameDescription} onCancel={() => setRenamingDescription(false)} />
          ) : (
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <Typography
                sx={{
                  margin: '0px',
                  color: (theme) => theme.palette.mode === 'dark' ?
                    (loading?.description ? '#2f3641' : '#ffffff') :
                    (loading?.description ? '#b2b2b2' : '#000000')
                }}
                variant="body1"
                fontWeight="bold"
                whiteSpace="pre-wrap"
                gutterBottom
              >
                {descriptionCard}
              </Typography>
              {loading?.description && (
                <Box>
                  <CircularProgress size={24} color="primary" />
                </Box>
              )}
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#1b212c' : '#f9f9f9'),
            boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
            position: 'relative',
          }}
        >
          <List
            ref={listRef}
            sx={{
              flexGrow: 1,
              overflowY: 'auto',
              padding: 3,
              paddingBottom: 1,
              '&::-webkit-scrollbar': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#555' : '#c1c1c1'),
                borderRadius: 2,
              },
            }}
          >
            {commentsArray?.map((comment, index) => (
              <CustomListItem key={index} comment={comment} divider={comment?.length === index} />
            ))}
          </List>
        </Box>

        <KanbanPriorityModal
          open={openPriorityModal}
          onClose={setOpenPriorityModal}
          kanbanId={card?.kanbanId}
        />
      </Box>


      <Box
        sx={{
          display: 'flex',
          gap: '8px',
          bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#2f3641' : '#ffffff'),
          padding: '5px 10px',
          borderRadius: '8px',
          boxShadow: '0 -2px 8px rgba(0, 0, 0, 0.1)',
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: 1,
          zIndex: 9999,
          flexDirection: 'column',

        }}>
        <Box sx={{
          display: files?.length > 0 ? 'flex' : 'none',
          alignItems: 'flex-end',
          gap: 1,
          bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#2f3641' : '#ffffff'),
          padding: '5px 10px',
          borderRadius: 1,
          boxShadow: '0 -2px 8px rgba(0,0,0,0.1)',
          width: '100%',
        }}>
          <DropZoneIconUpload
            multiple
            files={files}
            ref={dropzoneRef}
            onTriggerUpload={dropzoneRef.current?.triggerUpload}
            onDrop={handleDrop}
            onRemove={handleRemoveFile}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            gap: 1,
            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#2f3641' : '#ffffff'),
            padding: '5px 10px',
            borderRadius: 1,
            boxShadow: '0 -2px 8px rgba(0,0,0,0.1)',
            width: '100%',
          }}
        >
          <EmojiPicker
            id="emojiPicker"
            disabled={loading.comment}
            value={''}
            pickerSx={{ marginLeft: '30px' }}
            setValue={(emoji) => {
              commentTextRef.current += emoji;
              setCommentText(commentTextRef.current);
            }}
          />
          <IconButton onClick={() => handleUploadTrigger()}>
            <AttachFileIcon />
          </IconButton>
          <TextField
            size="small"
            variant="outlined"
            disabled={loading.comment}
            multiline
            fullWidth
            placeholder="Digite seu comentário..."
            value={commentText}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            maxRows={4}
            inputRef={textFieldRef}
            sx={{
              bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#1b212c' : '#f9f9f9'),
              borderRadius: 1,
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'transparent',
                },
                '&:hover fieldset': {
                  borderColor: (theme) => (theme.palette.mode === 'dark' ? '#5e6c84' : '#c1c1c1'),
                },
              },
            }}
          />
          <IconButton disabled={loading.comment || (!commentTextRef.current && files?.length === 0)} onClick={handleSendMessage}>
            {loading.comment ? <CircularProgress size={20} color="inherit" /> : <SendIcon />}
          </IconButton>
        </Box>
      </Box>

    </>

  );
};

export default KanbanCardDetailsAndCommentsSection;

const Label = styled(Typography)(({ theme }) => ({
  fontSize: '0.8rem',
  fontWeight: 600,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(0.5),
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: '10px',
  fontSize: '0.875rem',
  fontWeight: 500,
  marginRight: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
}));
