import React, { useRef, forwardRef, useImperativeHandle } from 'react';  // Adicionando forwardRef e useImperativeHandle
import { useDropzone } from 'react-dropzone';
// @mui
import { Box, Stack, Button, IconButton, Typography, StackProps } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
// assets


import RejectionFiles from './errors/RejectionFiles';
import MultiFilePreview from './preview/MultiFilePreview';
import SingleFilePreview from './preview/SingleFilePreview';
import UploadIllustration from '../../assets/illustrations/UploadIllustration';

const StyledDropZone = styled('div')(({ theme }) => ({
    outline: 'none',
    cursor: 'pointer',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create('padding'),
    backgroundColor: theme.palette.background.neutral,
    border: `1px solid ${alpha(theme.palette.grey[500], 0.32)}`,
    '&:hover': {
        opacity: 0.72,
    },
}));

// Alterando para forwardRef e usando useImperativeHandle
const DropZoneIconUpload = forwardRef(({
    disabled,
    multiple = false,
    error,
    helperText,
    file,
    onDelete,
    files,
    thumbnail,
    onUpload,
    onRemove,
    onRemoveAll,
    sx,
    ...other
}, ref) => {
    const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
        multiple,
        disabled,
        ...other,
    });

    const inputRef = useRef(null);  // Referência para o input

    // Expondo a função triggerUpload para o componente pai
    useImperativeHandle(ref, () => ({
        triggerUpload: () => {
            if (inputRef.current) {
                inputRef.current.click();  // Dispara o clique no input
            }
        },
    }));

    const hasFile = !!file && !multiple;
    const hasFiles = files && multiple && files.length > 0;
    const isError = isDragReject || !!error;

    return (
        <Box sx={{ width: 1, position: 'relative', ...sx }}>
            <StyledDropZone
                {...getRootProps()}
                sx={{
                    ...(isDragActive && { opacity: 0.72 }),
                    ...(isError && {
                        color: 'error.main',
                        bgcolor: 'error.lighter',
                        borderColor: 'error.light',
                    }),
                    ...(disabled && {
                        opacity: 0.48,
                        pointerEvents: 'none',
                    }),
                    ...(hasFile || (hasFiles && { display: "none", padding: '12% 0' })),
                }}
            >
                <input
                    {...getInputProps()}
                    ref={inputRef}  // Atribuindo a referência ao input
                />

                {hasFile && <SingleFilePreview file={file} />}
            </StyledDropZone>

            {helperText && helperText}

            <RejectionFiles fileRejections={fileRejections} />

            {hasFile && onDelete && (
                <IconButton
                    size="small"
                    onClick={onDelete}
                    sx={{
                        top: 16,
                        right: 16,
                        zIndex: 9,
                        position: 'absolute',
                        color: (theme) => alpha(theme.palette.common.white, 0.8),
                        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                        '&:hover': {
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                        },
                    }}
                >
                    icone
                </IconButton>
            )}

            {hasFiles && (
                <>
                    <Box>
                        <MultiFilePreview files={files} thumbnail={thumbnail} onRemove={onRemove} />
                    </Box>

                    <Stack direction="row" justifyContent="flex-end" spacing={1}>
                        {onRemoveAll && (
                            <Button color="inherit" variant="outlined" size="small" onClick={onRemoveAll}>
                                Remove all
                            </Button>
                        )}

                        {onUpload && (
                            <Button size="small" variant="contained" onClick={onUpload}>
                                Upload files
                            </Button>
                        )}
                    </Stack>
                </>
            )}
        </Box>
    );
});

export default DropZoneIconUpload;

// Placeholder Component
function Placeholder({ sx, ...other }) {
    return (
        <Stack
            spacing={5}
            alignItems="center"
            justifyContent="center"
            direction={{
                xs: 'column',
                md: 'row',
            }}
            sx={{
                width: 1,
                textAlign: {
                    xs: 'center',
                    md: 'left',
                },
                ...sx,
            }}
            {...other}
        >
            <div style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Typography variant="body2" sx={{ color: 'text.secondary', padding: '8px 0px 8px 0px' }} >
                    Solte ou clique
                    <Typography
                        variant="body2"
                        component="span"
                        sx={{
                            mx: 0.5,
                            color: 'primary.main',
                            textDecoration: 'underline',
                        }}
                    >
                        aqui
                    </Typography>
                    para anexar um arquivo
                </Typography>
            </div>
        </Stack>
    );
}
